import React, { useState } from "react";
import {
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs"; // Import dayjs
import ErrorText from "./ErrorText";

const NewDatePicker = ({
  label,
  value,
  onChange,
  error = false,
  minYear,
  maxYear,
  stackSx = {},
}) => {
  const theme = useTheme();
  const [selectedDate, setSelectedDate] = useState({
    day: value ? dayjs(value).date() : "", // Use dayjs().date() to get the day of the month
    month: value ? dayjs(value).month() + 1 : "month", // Use dayjs().month() to get the month (0-based index)
    year: value ? dayjs(value).year() : "", // Use dayjs().year() to get the year
  });

  const numberInputSx = {
    width: "100%",
    "& .MuiOutlinedInput-input": {
      textAlign: "center",
      color: theme.text.primary,
      fontSize: theme.typography.heading1,
      fontWeight: theme.typography.heading2Bold,
      p: 0,
      height: "unset",
      minHeight: "unset",
      borderBottom: "2px solid",
      borderColor: alpha(theme.text.basic, 0.4),
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },

    "& .MuiOutlinedInput-input::placeholder": {
      fontWeight: 400,
      color: theme.text.basic,
    },

    "& fieldset": { border: "none" },
  };
  const selectInputSx = {
    width: "100%",
    "& .MuiOutlinedInput-input": {
      textAlign: "center",
      color:
        selectedDate.month === "month"
          ? alpha(theme.text.basic, 0.4)
          : theme.text.primary,
      fontSize: theme.typography.heading1,
      fontWeight: theme.typography.heading2Bold,
      p: 0,
      height: "unset",
      minHeight: "unset",
      borderBottom: "2px solid",
      borderColor: alpha(theme.text.basic, 0.4),
      borderRadius: 0,
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "& .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
      p: 0,
    },

    "& .MuiSelect-select": {
      minHeight: "unset !important",
    },
    "& fieldset": { border: "none" },
    "& > *": {
      paddingRight: "0 !important",
    },
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;

    setSelectedDate((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "year" || name === "month" || name === "day") {
      const { year, month, day } = {
        ...selectedDate,
        [name]: value,
      };

      if (year && month && day) {
        // Check if the selected day is valid for the selected month and year
        const daysInMonth = dayjs(`${year}-${month}`).daysInMonth();
        if (day <= daysInMonth) {
          const dateString = `${year}-${month}-${day}`;
          if (!isNaN(dayjs(dateString))) {
            return onChange(dayjs(dateString));
          }
        } else {
          return onChange(null);
        }
      }
    }
  };

  const generateDays = () => {
    const month = selectedDate.month ? selectedDate.month : 1;
    const year = selectedDate.year ? selectedDate.year : dayjs().year();
    const daysInMonth = dayjs(`${year}-${month}`).daysInMonth();
    return Array.from({ length: daysInMonth }, (_, index) => index + 1).map(
      (day) => (
        <MenuItem key={day} value={day}>
          {day}
        </MenuItem>
      )
    );
  };

  const generateMonths = () => {
    return Array.from({ length: 12 }, (_, index) => index + 1).map((month) => (
      <MenuItem key={month} value={month}>
        {dayjs()
          .month(month - 1)
          .format("MMM")}
      </MenuItem>
    ));
  };

  const generateYears = () => {
    const currentYear = dayjs().year();
    return Array.from({ length: 10 }, (_, index) => currentYear - index).map(
      (year) => (
        <MenuItem key={year} value={year}>
          {year}
        </MenuItem>
      )
    );
  };

  return (
    <Stack sx={{ width: "100%", marginTop: theme.margin.margin1, ...stackSx }}>
      <Typography
        sx={{
          color: theme.text.basic,
          fontSize: theme.typography.heading1,
          fontWeight: theme.typography.heading1Bold,
          textWrap: "nowrap",
        }}
      >
        {label}
      </Typography>
      <Grid container spacing={2} sx={{ marginTop: "0px" }}>
        <Grid item xs={4}>
          <Select
            inputProps={{ IconComponent: () => null }}
            value={selectedDate.month || ""}
            onChange={handleSelectChange}
            name="month"
            sx={selectInputSx}
          >
            <MenuItem disabled selected value={"month"}>
              month
            </MenuItem>
            {generateMonths()}
          </Select>
        </Grid>
        <Grid item xs={4}>
          <TextField
            placeholder="day"
            value={selectedDate.day}
            onChange={handleSelectChange}
            name="day"
            type="number"
            inputProps={{ min: 1, max: 31 }} // Limit input to 1-31
            required
            sx={numberInputSx}
          >
            {generateDays()}
          </TextField>
        </Grid>
        <Grid item xs={4}>
          <TextField
            placeholder="year"
            value={selectedDate.year}
            onChange={handleSelectChange}
            name="year"
            type="number"
            required
            sx={numberInputSx}
          >
            {generateYears()}
          </TextField>
        </Grid>
      </Grid>
      <ErrorText error={error} />
    </Stack>
  );
};

export default NewDatePicker;
