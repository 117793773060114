import {
  Collapse,
  FormControl,
  RadioGroup as RadioGroupMui,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import ErrorText from "./ErrorText";

const RadioGroup = ({ children, title, name, value, onChange, error }) => {
  const { text, typography, margin } = useTheme();
  return (
    <FormControl fullWidth>
      <Typography
        sx={{
          mt: margin.margin1,
          color: text.basic,
          fontSize: typography.heading1,
          fontStyle: "normal",
          fontWeight: typography.heading1Bold,
          lineHeight: "28px",
        }}
      >
        {title}
      </Typography>
      <RadioGroupMui
        value={value}
        onChange={onChange}
        aria-labelledby="demo-radio-buttons-group-label"
        name={name}
      >
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: { xs: "10%", sm: "none" },
          }}
        >
          {children}
        </Stack>
      </RadioGroupMui>
      <Collapse in={Boolean(error)}>
        <ErrorText error={error} />
      </Collapse>
    </FormControl>
  );
};

export default RadioGroup;
