import { useState } from "react";
import { doc, deleteDoc } from "firebase/firestore";
import { db } from "../services/firebase";

const useDeleteData = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const deleteDataById = async (collectionPath, id) => {
    try {
      setLoading(true);
      setError(false);
      setErrorMessage("");
      setSuccess(false);

      // Delete document by ID in Firestore
      const docRef = doc(db, collectionPath, id);
      await deleteDoc(docRef);

      setSuccess(true);
    } catch (error) {
      console.error(error);
      setError(true);
      setErrorMessage(error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, errorMessage, success, deleteDataById };
};

export default useDeleteData;
