import { Box, Tab, Tabs, useTheme } from "@mui/material";
import React from "react";

const TabComponent = ({
  value,
  handleChange,
  tabItem = [],
  tabSx = {},
  buttonSx = {},
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "background.main",
      }}
    >
      <Tabs
        sx={{
          width: "calc(100% + 48px)",
          ml: "-24px",
          marginTop: theme.margin.margin2,
          boxShadow: "0px 5px 4px #E9ECEF",
          ...tabSx,
        }}
        value={value}
        onChange={handleChange}
        centered
      >
        {tabItem.map((item) => (
          <Tab
            key={item}
            label={item}
            sx={{
              width: "50%",
              fontFamily: theme.typography.fontFamily2,
              fontSize: "18px",
              fontWeight: theme.typography.heading1Bold,
              textTransform: "capitalize",
              ...buttonSx,
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default TabComponent;
