import * as amplitude from "@amplitude/analytics-browser";
import React, { useEffect } from "react";
import { InputTextArea } from "../global/form/InputTextArea";
import { Stack } from "@mui/material";
import PrimaryButton from "../global/button/PrimaryButton";
import { useForm } from "react-hook-form";
import { TechSupportSchema } from "../../validators/TechSupportSchema";
import { FileUpload } from "../global/form/FileUpload";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "../../hooks/store/useAuth";
import useFileUpload from "../../hooks/useFileUpload";
import FileUploadDialog from "../global/dialog/file-upload-dialog/FileUploadDialog";
import { fileUploadStatus } from "../../constants/fileUploadStatus";
import BtnToast from "../global/toast/BtnToast";
import useSetData from "../../hooks/useSetData";
import { generateUniqueId } from "../../functions/generateUniqueId";

const TechSupportForm = () => {
  useEffect(() => {
    amplitude.logEvent("TechForm_Visited");
  }, []);

  const { isLoggedIn, userId, email } = useAuth();
  const { setData, loading } = useSetData();
  const { handleUpload, uploading, progress } = useFileUpload();
  const [showToast, setShowToast] = React.useState(false);
  const [userEmail, setUserEmail] = React.useState("");

  const { handleSubmit, control, formState, reset } = useForm({
    defaultValues: {
      file: null,
      email: email,
      reality: "",
      expectedResult: "",
      steps: "",
    },
    resolver: yupResolver(TechSupportSchema),
    mode: "onChange",
  });

  async function submitHandler(data) {
    try {
      setUserEmail(data.email);
      let payload = {
        steps: data.steps,
        expectedResult: data.expectedResult,
        reality: data.reality,
        email: data.email,
      };

      if (data.file) {
        const urls = await handleUpload(data.file, "tech-support");
        payload.attachments = urls;
      }

      if (isLoggedIn) {
        payload.userId = userId;
      }

      const id = generateUniqueId();
      await setData("/techSupports", payload, id);

      reset({
        file: null,
        email: email,
        reality: "",
        expectedResult: "",
        steps: "",
      });

      amplitude.logEvent("TechForm_Submitted");
      return setShowToast(true);
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <Stack component={"form"} onSubmit={handleSubmit(submitHandler)}>
      <InputTextArea
        label="Steps you took"
        name={"steps"}
        placeholder="1. Clicked...&#10;2.&#10;3."
        control={control}
        errorSx={{ mt: "6px", ml: "12px", fontSize: "16px" }}
        minRows={3}
      />

      <InputTextArea
        label="What you expected to happen"
        name={"expectedResult"}
        placeholder="Birthday accepted"
        control={control}
        errorSx={{ mt: "6px", ml: "12px", fontSize: "16px" }}
      />

      <InputTextArea
        label="What actually happened"
        name={"reality"}
        placeholder="Error says “enter valid birthday”"
        control={control}
        errorSx={{ mt: "6px", ml: "12px", fontSize: "16px" }}
      />

      <InputTextArea
        label="Enter your email address"
        name={"email"}
        placeholder="Enter your email address"
        control={control}
        errorSx={{ mt: "6px", ml: "12px", fontSize: "16px" }}
        minRows={1}
      />

      <FileUpload
        label="Screenshots & Screen Recordings"
        name={"file"}
        placeholder="Click here to attach files"
        control={control}
        errorSx={{ mt: "6px", ml: "12px", fontSize: "16px" }}
      />

      <PrimaryButton
        type="submit"
        isLoading={uploading === fileUploadStatus.IN_PROGRESS || loading}
        disabled={!formState.isValid}
        sx={{
          mt: "48px",
          width: { xs: "220px", md: "unset" },
          alignSelf: "center",
        }}
      >
        Submit
      </PrimaryButton>
      <BtnToast
        text={`We'll be in touch at ${userEmail}`}
        visibility={showToast}
        setVisibility={setShowToast}
      />

      <FileUploadDialog
        openDialog={uploading === fileUploadStatus.IN_PROGRESS}
        FileUploadProgressInfo={progress}
      />
    </Stack>
  );
};

export default TechSupportForm;
