import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import {
  Box,
  Stack,
  TextField,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
require("dayjs/plugin/customParseFormat");
dayjs.extend(require("dayjs/plugin/customParseFormat"));

const NewTimePicker = ({
  onChange,
  stackStyle = {},
  defaultValue = {},
  required = false,
}) => {
  const theme = useTheme();
  const [hours, setHours] = useState(
    defaultValue?.hours ? defaultValue.hours : null
  );
  const [minutes, setMinutes] = useState(
    defaultValue?.minutes ? defaultValue.minutes : null
  );
  const [isAMSelected, setIsAMSelected] = useState(
    defaultValue?.isAMSelected
      ? defaultValue.isAMSelected === "am"
        ? true
        : false
      : true
  );

  const handleHoursChange = (event) => {
    const newHours = parseInt(event.target.value);
    if (!isNaN(newHours)) {
      setHours(newHours);
    } else {
      setHours(null);
    }
  };

  const handleMinutesChange = (event) => {
    const newMinutes = parseInt(event.target.value);
    if (!isNaN(newMinutes)) {
      setMinutes(newMinutes);
    } else {
      setMinutes(null);
    }
  };

  const handleAMClick = () => {
    setIsAMSelected(true);
  };

  const handlePMClick = () => {
    setIsAMSelected(false);
  };

  useEffect(() => {
    if (hours !== null && minutes !== null && isAMSelected !== null) {
      // Convert the time to 24-hour format
      const formattedTime = dayjs(
        `${hours}:${minutes}:${isAMSelected ? "AM" : "PM"}`,
        "h:mm:A"
      ).format("HH:mm:ss");
      onChange(formattedTime);
    } else {
      onChange(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hours, minutes, isAMSelected]);

  return (
    <>
      <Stack
        sx={{
          display: "inline-flex",
          mt: theme.margin.margin3,
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "flex-start",
          gap: { xs: "8px", sm: "20px" },
          ...stackStyle,
        }}
      >
        <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
          <TextField
            required={required}
            type="number"
            value={hours === null ? "" : hours}
            onChange={handleHoursChange}
            inputProps={{ min: 0, max: 12 }}
            sx={{
              width: "100%",
              minWidth: "20px",
              maxWidth: "40px",
              "& .MuiOutlinedInput-input": {
                textAlign: "center",
                color: theme.text.primary,
                fontSize: theme.typography.heading1,
                fontWeight: theme.typography.heading2Bold,
                p: 0,
                height: "unset",
                minHeight: "unset",
                borderBottom: "2px solid",
                borderColor: alpha(theme.text.basic, 0.4),
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "& .MuiOutlinedInput-input::placeholder": {
                fontWeight: 400,
              },
              "& fieldset": { border: "none" },
              "& .MuiOutlinedInput-root": {
                paddingRight: "unset !important",
              },
            }}
          />
          <Typography sx={{ marginInline: "12px" }}>:</Typography>
          <TextField
            type="number"
            value={minutes === null ? "" : minutes}
            onChange={handleMinutesChange}
            inputProps={{ min: 0, max: 59 }}
            required={required}
            sx={{
              width: "100%",
              maxWidth: "40px",
              minWidth: "20px",
              "& .MuiOutlinedInput-input": {
                textAlign: "center",
                color: theme.text.primary,
                fontSize: theme.typography.heading1,
                fontWeight: theme.typography.heading2Bold,
                p: 0,
                height: "unset",
                minHeight: "unset",
                borderBottom: "2px solid",
                borderColor: alpha(theme.text.basic, 0.4),
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "& .MuiOutlinedInput-input::placeholder": {
                fontWeight: 400,
              },
              "& fieldset": { border: "none" },
              "& .MuiOutlinedInput-root": {
                paddingRight: "unset !important",
              },
            }}
          />
        </Stack>

        <Stack sx={{ flexDirection: "row" }}>
          <Box
            onClick={handleAMClick}
            sx={{
              fontSize: "14px",
              lineHeight: { xs: "12px", sm: "18px" },
              fontFamily: theme.typography.fontFamily,
              padding: { xs: "8px", sm: "12px" },
              border: "1px solid",
              borderTopLeftRadius: { xs: "40%", sm: "50%" },
              borderBottomLeftRadius: { xs: "40%", sm: "50%" },
              borderRight: ".5px solid",
              color:
                isAMSelected !== null && isAMSelected
                  ? theme.text.main
                  : alpha(theme.text.basic, 0.5),
              backgroundColor:
                isAMSelected !== null && isAMSelected
                  ? theme.palette.primary.main
                  : "background.main",
              borderColor:
                isAMSelected !== null && isAMSelected
                  ? theme.palette.primary.main
                  : alpha(theme.text.basic, 0.5),
              cursor: "pointer",
            }}
          >
            AM
          </Box>
          <Box
            onClick={handlePMClick}
            sx={{
              fontSize: "14px",
              lineHeight: { xs: "12px", sm: "18px" },
              fontFamily: theme.typography.fontFamily,
              padding: { xs: "8px", sm: "12px" },
              border: "1px solid",
              borderTopRightRadius: { xs: "40%", sm: "50%" },
              borderBottomRightRadius: { xs: "40%", sm: "50%" },
              borderLeft: "0px solid",
              color:
                isAMSelected !== null && !isAMSelected
                  ? theme.text.main
                  : alpha(theme.text.basic, 0.5),
              backgroundColor:
                isAMSelected !== null && !isAMSelected
                  ? theme.palette.primary.main
                  : "background.main",
              borderColor:
                isAMSelected !== null && !isAMSelected
                  ? theme.palette.primary.main
                  : alpha(theme.text.basic, 0.5),
              cursor: "pointer",
            }}
          >
            PM
          </Box>
        </Stack>
      </Stack>
    </>
  );
};

export default NewTimePicker;
