import React from "react";
import {
  MenuItem,
  Select,
  Stack,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import DownArrowIcon from "../../../icons/form-icon/DownArrowIcon";
import { Controller } from "react-hook-form";
export const SelectInput = ({
  control,
  name,
  label = false,
  options = [],
  description = false,
  removeMargin = false,
  onChangeOption = false,
  componentStyle = {},
}) => {
  const { text } = useTheme();
  const theme = useTheme();

  const generateSingleOptions = () => {
    return options.map((option) => {
      return (
        <MenuItem
          key={option.value}
          value={option.value}
          sx={{ textWrap: "balance" }}
        >
          {option.label}
        </MenuItem>
      );
    });
  };
  return (
    <Stack
      sx={{
        mt: removeMargin ? "0px" : "24px",
        flexDirection: "column",
        width: "100%",
        flexGrow: 0,
        ...componentStyle,
      }}
    >
      {/* label  */}
      {label && (
        <Typography
          sx={{
            color: text.secondary,
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "22px",
          }}
        >
          {label}
        </Typography>
      )}

      {/* select input */}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <Select
            fullWidth
            onChange={(e) => {
              onChange(e);
              // Invoke the callback with the selected value
              onChangeOption && onChangeOption(e.target.value);
            }}
            value={value}
            IconComponent={DownArrowIcon}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-input": {
                color: theme.text.primary,
                fontSize: theme.typography.heading1,
                fontWeight: theme.typography.heading2Bold,
                p: 0,
                height: "unset",
                minHeight: "unset",
                borderBottom: "2px solid",
                borderColor: alpha(theme.text.basic, 0.4),
              },

              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },

              "& .MuiOutlinedInput-input::placeholder": {
                fontWeight: 400,
              },

              "& .MuiSelect-select ": {
                borderRadius: 0,
              },
              "& fieldset": { border: "none" },
            }}
          >
            {generateSingleOptions()}
          </Select>
        )}
      />

      {/* information text */}
      {description && (
        <Typography
          sx={{
            mt: "10px",
            alignSelf: "stretch",
            color: text.info,
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "22px /* 122.222% */",
          }}
        >
          {description}
        </Typography>
      )}
    </Stack>
  );
};
