import {
  Box,
  Button,
  Slide,
  Stack,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BrandIcon from "../../icons/BrandIcon";
import { useCommon } from "../../hooks/store/useCommon";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/store/useAuth";
import Hamburger from "hamburger-react";
import PlusIcon from "../../icons/navbar/PlusIcon";
import MessageIcon from "../../icons/navbar/MessageIcon";
import SettingIcon from "../../icons/navbar/SettingIcon";
import MenIcon from "../../icons/navbar/MenIcon";
import LogoutIcon from "../../icons/navbar/LogoutIcon";
import LogoIcon from "../../icons/navbar/LogoIcon";
import { matchCountryCode } from "../../utils/SmsSeviceChecker";

const Navbar = () => {
  const { navbarVisibility } = useCommon();
  const [openResponsiveNav, setOpenResponsiveNav] = useState(false);
  const { isLoggedIn, logout, name, phone, CloseLeadStatus } = useAuth();
  const { settings } = useCommon();
  const { palette, text } = useTheme();
  const navigate = useNavigate();

  const loggedInLinks = [
    {
      title: "New Check-in",
      icon: <PlusIcon />,
      isLoggedIn: true,
      onClick: () => {
        setOpenResponsiveNav(false);
        navigate("/measurement");
      },
    },
    {
      title: "Profile",
      icon: <MenIcon />,
      isLoggedIn: true,
      onClick: () => {
        setOpenResponsiveNav(false);
        navigate("/profile");
      },
    },
    {
      title: "Settings",
      icon: <SettingIcon />,
      isLoggedIn: true,
      onClick: () => {
        setOpenResponsiveNav(false);
        navigate("/settings/notification");
      },
    },
    {
      title:
        !isLoggedIn || !phone
          ? "Get Help"
          : matchCountryCode(phone, settings?.SmsServicesAvailability) &&
            CloseLeadStatus !== "BBB Customer"
          ? "Get Help"
          : "Tech Support",
      icon: <MessageIcon />,
      isLoggedIn: true,
      onClick: () => {
        setOpenResponsiveNav(false);
        navigate("/tech-support");
      },
    },
    {
      title: "Log out",
      icon: <LogoutIcon />,
      isLoggedIn: true,
      onClick: () => {
        logout();
        setOpenResponsiveNav(false);
        return navigate("/");
      },
    },
    {
      title: "Log in",
      icon: <LogoutIcon />,
      isLoggedIn: false,
      onClick: () => {
        logout();
        setOpenResponsiveNav(false);
        return navigate("/login");
      },
    },
    {
      title: "Tech Support",
      icon: <MessageIcon />,
      isLoggedIn: false,
      onClick: () => {
        logout();
        setOpenResponsiveNav(false);
        return navigate("/tech-support");
      },
    },
  ];

  // prevent body scrolling
  useEffect(() => {
    const body = document.body;

    // Handle body overflow based on navbarVisibility
    if (openResponsiveNav) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }

    // Clean up the effect
    return () => {
      body.style.overflow = "auto";
    };
  }, [openResponsiveNav]);

  return (
    navbarVisibility && (
      <Box
        sx={{
          backgroundColor: "background.secondary",
          width: "100%",
          position: "sticky",
          top: 0,
          zIndex: 101,
        }}
      >
        <Stack
          sx={{
            maxWidth: "550px",
            mx: "auto",
            px: { xs: "12px", sm: "20px" },
            py: { xs: "12px", sm: "20px" },
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            position: "relative",
            backgroundColor: "background.main",
          }}
        >
          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              zIndex: 100,
            }}
          >
            <Stack
              sx={{
                flexDirection: "row",
                gap: "12px",
                alignItems: "center",
                justifyContent: "center",
                zIndex: "100",
              }}
            >
              <Link to={isLoggedIn ? "/myprogress" : "/"}>
                <BrandIcon
                  sx={{
                    zIndex: 99,
                    cursor: "pointer",
                    width: { xs: "38px", sm: "42px" },
                    height: { xs: "38px", sm: "42px" },
                  }}
                />
              </Link>
              <Typography
                component={Link}
                to={isLoggedIn ? "/myprogress" : "/"}
                sx={{
                  fontSize: "18.5px",
                  cursor: "pointer",
                  textDecoration: "none",
                  color: text.basic,
                }}
              >
                Body Fat Calculator
              </Typography>
            </Stack>

            {/* code for responsive menu */}
            <Box>
              <Hamburger
                toggled={openResponsiveNav}
                toggle={setOpenResponsiveNav}
                size={27}
                zIndex={100}
                rounded={true}
                color="#545454"
              />
            </Box>
          </Stack>

          <Slide in={openResponsiveNav} direction="left">
            <Stack
              sx={{
                background: alpha(palette.background.main, 0.8),
                zIndex: 100,
                position: "absolute",
                top: "0%",
                right: 0,
                width: "100%",
                minHeight: "100vh",
                alignItems: "flex-end",
              }}
              onClick={() => setOpenResponsiveNav(false)}
            >
              <Stack
                sx={{
                  backgroundColor: "background.primary",
                  width: "86%",
                  minHeight: "100vh",
                  height: "100%",
                  paddingInline: "12px",
                  borderTopLeftRadius: "6px",
                  borderBottomLeftRadius: "6px",
                }}
                onClick={(e) => e.stopPropagation()}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    zIndex: 99,
                  }}
                >
                  {isLoggedIn ? (
                    <Typography
                      sx={{
                        color: text.main,
                        fontFamily: text.fontFamily2,
                        fontSize: { xs: "26px", sm: "28px" },
                        fontWeight: 700,
                        textTransform: "capitalize",
                        padding: "30px 15px 10px 15px",
                        borderBottom: "3px solid",
                        borderColor: text.main,
                      }}
                    >
                      {name}
                    </Typography>
                  ) : (
                    <Stack
                      onClick={() => navigate("/")}
                      sx={{
                        flexDirection: "row",
                        gap: "12px",
                        alignItems: "center",
                        padding: "30px 15px 10px 15px",
                        borderBottom: "3px solid",
                        borderColor: text.main,
                        cursor: "pointer",
                      }}
                    >
                      <LogoIcon
                        sx={{
                          zIndex: 99,
                          cursor: "pointer",
                          width: { xs: "40px" },
                          height: { xs: "40px" },
                        }}
                      />
                      <Typography
                        sx={{
                          color: text.main,
                          fontFamily: text.fontFamily2,
                          fontSize: "22px",
                          fontWeight: 700,
                          textTransform: "capitalize",
                          textDecoration: "none",
                        }}
                      >
                        BF Tracker
                      </Typography>
                    </Stack>
                  )}

                  {/* logged in user links */}
                  <Stack sx={{ mt: "8px" }}>
                    {loggedInLinks.map((link) => {
                      // login links
                      if (isLoggedIn && link?.isLoggedIn) {
                        return (
                          <Button
                            key={link.title}
                            variant="outlined"
                            startIcon={link.icon}
                            sx={{
                              color: text.main,
                              fontSize: "22px",
                              textTransform: "unset",
                              "&.MuiButton-root": {
                                justifyContent: "flex-start",
                                gap: "6px",
                              },
                            }}
                            onClick={link.onClick}
                          >
                            {link.title}
                          </Button>
                        );
                      }

                      // with out login links
                      if (!isLoggedIn && !link.isLoggedIn) {
                        return (
                          <Button
                            key={link.title}
                            variant="outlined"
                            startIcon={link.icon}
                            sx={{
                              color: text.main,
                              fontSize: "22px",
                              textTransform: "unset",
                              "&.MuiButton-root": {
                                justifyContent: "flex-start",
                                gap: "6px",
                              },
                            }}
                            onClick={link.onClick}
                          >
                            {link.title}
                          </Button>
                        );
                      }

                      return null;
                    })}
                  </Stack>
                </Box>
              </Stack>
            </Stack>
          </Slide>
        </Stack>
      </Box>
    )
  );
};

export default Navbar;
