import * as amplitude from "@amplitude/analytics-browser";
import { Button, Typography, useTheme } from "@mui/material";
import React from "react";
import PrimaryButton from "../../global/button/PrimaryButton";
import ErrorText from "../../global/form/ErrorText";
import { MuiOtpInput } from "mui-one-time-password-input";
import useApiCall from "../../../hooks/useApiCall";
import { useAuth } from "../../../hooks/store/useAuth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const EmailUpdateDialog = ({
  formData,
  openContainer,
  handleClose,
  setOpenContainer,
}) => {
  const { text } = useTheme();
  const theme = useTheme();
  const { apiCall, error, loading } = useApiCall();
  const { apiCall: apiCall2 } = useApiCall();
  const { userId, phone } = useAuth();
  const navigate = useNavigate();
  const [otp, setOtp] = React.useState("");
  const [errorMsg, setErrorMsg] = React.useState(false);

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  // code for send/resend otp
  let sendOtp = React.useCallback(async function (resend = false) {
    try {
      if (formData.email) {
        let response = await apiCall2("post", "/sendOtpMail", {
          body: { email: formData.email },
        });
        return resend && toast(response.message);
      }
    } catch (error) {
      return toast(error.response.data.message, { type: "error" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // code fo verify otp
  async function verifyOtp(otp) {
    try {
      if (otp.length !== 4) {
        return setErrorMsg({
          error: true,
          message: "Please provide a valid otp",
        });
      }

      setErrorMsg(false);
      let response = await apiCall("post", "/verifyOtpMail", {
        body: { email: formData.email, code: otp, userId },
      });
      toast(response.message);
      amplitude.logEvent("Profile_EmailChanged");

      if (phone !== formData.number) {
        return setOpenContainer("phone update dialog");
      } else {
        handleClose();
        return navigate("/myprogress");
      }
    } catch (error) {
      setErrorMsg({
        error: true,
        message: "Please provide a valid otp",
      });
      return toast(error.response.data.message, { type: "error" });
    }
  }

  React.useEffect(() => {
    openContainer === "email update dialog" && sendOtp();
  }, [sendOtp, openContainer]);

  // code for verify otp
  const handleComplete = async (finalValue) => {
    try {
      verifyOtp(finalValue);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Typography
        sx={{
          mt: "24px",
          fontFamily: theme.typography.fontFamily2,
          fontSize: theme.typography.headingBasic,
          color: theme.text.basic,
          fontWeight: theme.typography.headingBasicBold,
        }}
      >
        Enter OTP
      </Typography>
      <Typography
        sx={{
          mt: "24px",
          color: text.basic,
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: { xs: "14px", sm: "16px" },
          marginTop: theme.margin.margin3,
          lineHeight: "20px",
          textAlign: "center",
        }}
      >
        We have sent a verification code to your email address:{" "}
        <Typography
          component={"span"}
          sx={{
            color: text.primary,
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: { xs: "14px", sm: "16px" },
            marginTop: theme.margin.margin3,
            lineHeight: "20px",
            textAlign: "center",
          }}
        >
          {formData.email}
        </Typography>
        . Please check your inbox for the OTP.
      </Typography>

      <MuiOtpInput
        TextFieldsProps={{ type: "number" }}
        value={otp}
        length={4}
        onChange={handleChange}
        autoFocus
        onComplete={handleComplete}
        sx={{
          mx: "auto",
          mt: "24px",
          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
            {
              display: "none",
            },
          "& input[type=number]": {
            MozAppearance: "textfield",
          },

          "& .MuiOutlinedInput-input": {
            padding: { xs: "12px", md: "16px 14px" },
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: "16px",
            backgroundColor: "#ffffff",
            color: theme.text.primary,
            fontSize: theme.typography.heading1,
            fontWeight: theme.typography.heading2Bold,
          },
          "&.MuiOtpInput-Box": {
            maxWidth: "230px",
            flexWrap: "nowrap",
            justifyContent: "space-between",
            gap: { xs: "12px", sm: "10px", md: "15px" },
          },
        }}
      />

      <ErrorText error={error || errorMsg} />

      <PrimaryButton
        isLoading={loading}
        // onClick={() => verifyOtp(otp)}
        sx={{
          mt: "30px",
          p: "16px !important",
          width: "100%",
          fontSize: "18px !important",
        }}
      >
        Verify
      </PrimaryButton>

      <Typography
        sx={{
          mt: "18px",
          fontSize: { xs: "14px", sm: "16px" },
        }}
      >
        Didn’t receive an SMS?
      </Typography>
      <Button
        onClick={() => sendOtp(true)}
        variant="text"
        sx={{
          fontSize: { xs: "14px", sm: "16px" },
          textTransform: "unset",
          color: "#30a3fa",
        }}
      >
        Click here to send new code
      </Button>
    </>
  );
};

export default EmailUpdateDialog;
