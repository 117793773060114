const dayjs = require("dayjs");

export default function daysBetween(startDate, endDate) {
  const start = dayjs(startDate * 1000);
  const end = dayjs(endDate * 1000);

  // Calculate the difference in days
  const difference = end.diff(start, "day");
  return difference;
}
