import { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  orderBy,
  where,
  limit as firestoreLimit,
} from "firebase/firestore";
import { db } from "../services/firebase";

const useRealTimeData = (
  collectionPath,
  fieldName,
  operator,
  value,
  orderByField = null,
  orderByDirection = "asc",
  limit = null
) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setData] = useState(null);

  useEffect(() => {
    setLoading(true);
    setError(false);
    setErrorMessage("");

    // Query data in Firestore
    let q = query(
      collection(db, collectionPath),
      where(fieldName, operator, value)
    );

    // Add sorting if orderByField is provided
    if (orderByField) {
      q = query(q, orderBy(orderByField, orderByDirection));
    }

    // Add limiting if limit is provided
    if (limit) {
      q = query(q, firestoreLimit(limit));
    }

    const unsubscribe = onSnapshot(q, (snapshot) => {
      if (!snapshot.empty) {
        const updatedData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setData(updatedData);
      } else {
        setData(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [
    collectionPath,
    fieldName,
    operator,
    value,
    orderByField,
    orderByDirection,
    limit,
  ]);

  return { loading, error, errorMessage, data };
};

export default useRealTimeData;
