import { Button, useTheme } from "@mui/material";
import React from "react";

const PrimaryButton = ({
  children,
  isLoading = false,
  disabled = false,
  sx,
  ...rest
}) => {
  const { button, text } = useTheme();
  return (
    <Button
      disabled={isLoading || disabled}
      disableRipple
      sx={{
        textTransform: "unset",
        backgroundColor: button.primary.color,
        color: button.primary.textPrimary,
        maxWidth: { xs: "200px", sm: "220px" },
        width: { xs: "100%", md: "unset" },

        // css for disable button
        "&.Mui-disabled": {
          pointerEvents: "unset",
          cursor: "no-drop",
          backgroundColor: isLoading ? button.primary.color : "transparent",
          color: text.disabled,
          border: isLoading ? "none" : "2px solid",
          borderColor: isLoading ? "" : button.primary.disabled,
        },

        // css for hover button
        "&.MuiButton-root:hover": {
          backgroundColor: !isLoading && !disabled && button.primary.hover,
        },

        "&.MuiButton-root:active": {
          backgroundColor: !isLoading && !disabled && button.primary.clicked,
        },

        // css for loading animation on is loading true
        "&.MuiButton-root": {
          minWidth: "unset",
          padding: { xs: "14px", sm: "16px" },
          borderRadius: "14px",
          boxShadow: "none",
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "18px",
        },
        ".bouncing-loader": {
          display: "flex",
          padding: "1px 12px",
          alignItems: "center",
          gap: "12px",
        },
        ".bouncing-loader > div": {
          width: "18px",
          height: "18px",
          borderRadius: "50%",
          backgroundColor: button.primary.textDisabled,
          opacity: 1,
          animation: "bouncing-loader 0.6s infinite alternate",
        },
        "@keyframes bouncing-loader": {
          to: {
            opacity: 0.1,
            //transform: "translateY(-2px)"
          },
        },
        ".bouncing-loader > div:nth-of-type(2)": { animationDelay: "0.2s" },
        ".bouncing-loader > div:nth-of-type(3)": { animationDelay: "0.4s" },
        ...sx,
      }}
      {...rest}
    >
      {isLoading ? (
        <div className="bouncing-loader">
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        children
      )}
    </Button>
  );
};

export default PrimaryButton;
