import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PrimaryButton from "../global/button/PrimaryButton";
import { Box, Stack, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputFiledWithSelect } from "../global/form/InputFiledWithSelect";
import {
  MeasurementsSourceMenAfterLogin,
  MeasurementsSourceWomenAfterLogin,
} from "../../inputConfigs/MeasurementsSource";
import {
  MenMeasurementSchemaAfterLogin,
  WomenMeasurementSchemaAfterLogin,
} from "../../validators/MeasurementSchema";
import { useAuth } from "../../hooks/store/useAuth";
import useUpdateData from "../../hooks/useUpdateData";
import dayjs from "dayjs";
import { generateUniqueId } from "../../functions/generateUniqueId";
import useSetData from "../../hooks/useSetData";
import { cmToInches, kgToLb } from "../../functions/unitConvertor";
import { useNavigate } from "react-router-dom";
import MainContainer from "../global/container/MainContainer";
import { genders } from "../../constants/gender";
import { measurementUnits } from "../../constants/measurementUnit";
import OutlinedButton from "../global/button/OutlinedButton";
import SelectCheckInDateDialog from "./SelectCheckInDateDialog";
import BtnToast from "../global/toast/BtnToast";
import { useCommon } from "../../hooks/store/useCommon";

const AfterLoggedInMeasurementsForm = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { setData, loading } = useSetData();
  const { updateData } = useUpdateData();
  const { sex, unitPreference, setUseDetails, userId, height } = useAuth();
  const { setIsProgressCheckIn } = useCommon();
  const [value, setValue] = React.useState(
    unitPreference === measurementUnits.METRIC ? 1 : 0
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const storeCheckIn = useCallback(async function (data) {
    try {
      const { checkInDate, ...payload } = data;

      // create new record
      const recordId = generateUniqueId();
      return await setData(
        "/checkIns",
        { userId, ...payload },
        recordId,
        checkInDate
      );
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    updateData(
      "/users",
      {
        unitPreference: newValue
          ? measurementUnits.METRIC
          : measurementUnits.IMPERIAL,
      },
      userId
    );
    setUseDetails({
      unitPreference: newValue
        ? measurementUnits.METRIC
        : measurementUnits.IMPERIAL,
    });
  };

  const {
    handleSubmit,
    control,
    setValue: setFormValue,
    watch,
    formState,
  } = useForm({
    defaultValues: {
      checkInDate: dayjs(),
      weight: "",
      waist: "",
      neck: "",
      ...(sex === genders.FEMALE ? { hip: "" } : {}),
    },
    resolver: yupResolver(
      sex === genders.FEMALE
        ? WomenMeasurementSchemaAfterLogin
        : MenMeasurementSchemaAfterLogin
    ),
    mode: "onTouched",
  });

  // display error message onsubmit form if any form error exist
  useEffect(() => {
    if (Object.keys(formState.errors).length > 0) setShowToast(true);
  }, [formState.errors]);

  const onSubmit = async (data) => {
    try {
      const convertedData = {
        weight:
          unitPreference === measurementUnits.IMPERIAL
            ? data.weight
            : kgToLb(data.weight), // in lbs
        waist:
          unitPreference === measurementUnits.IMPERIAL
            ? data.waist
            : cmToInches(data.waist), // in inches
        neck:
          unitPreference === measurementUnits.IMPERIAL
            ? data.neck
            : cmToInches(data.neck), // in inches
        height,
        gender: sex,
        ...(sex === genders.FEMALE
          ? {
              hip:
                unitPreference === measurementUnits.IMPERIAL
                  ? data?.hip
                  : cmToInches(data?.hip),
            }
          : {}),
        checkInDate: data.checkInDate,
      };

      await storeCheckIn(convertedData);
      setIsProgressCheckIn(true);
      return navigate("/myprogress");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <MainContainer>
      <Stack direction="row" spacing={1} alignItems="center">
        <Box
          sx={{
            width: "100%",
            bgcolor: "background.main",
          }}
        >
          <Tabs
            sx={{
              width: "calc(100% + 48px)",
              ml: "-24px",
              marginTop: theme.margin.margin2,
              boxShadow: "0px 5px 4px #E9ECEF",
            }}
            value={value}
            onChange={handleChange}
            centered
          >
            <Tab
              label={measurementUnits.IMPERIAL}
              sx={{
                width: "50%",
                fontFamily: theme.typography.fontFamily2,
                fontSize: "18px",
                fontWeight: theme.typography.heading1Bold,
                textTransform: "capitalize",
              }}
            />
            <Tab
              label={measurementUnits.METRIC}
              sx={{
                width: "50%",
                fontFamily: theme.typography.fontFamily2,
                fontSize: "18px",
                fontWeight: theme.typography.heading1Bold,
                textTransform: "capitalize",
              }}
            />
          </Tabs>
        </Box>
      </Stack>

      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          ml: "5%",
          mt: "24px",
          gap: "12px",
        }}
      >
        <Typography sx={{ fontSize: "22px", fontWeight: 600 }}>
          {dayjs(watch()?.checkInDate)?.format("MMM D")}
        </Typography>

        <OutlinedButton
          sx={{
            border: "none !important",
            display: "inline !important",
            "&.MuiButton-root": {
              color: "info.secondary",
              fontSize: { xs: "14px", sm: "16px" },
              p: 0,
              minWidth: 0,
            },
          }}
          onClick={() => setOpenDialog(true)}
        >
          Edit
        </OutlinedButton>
      </Stack>
      <Stack
        component={"form"}
        onSubmit={handleSubmit(onSubmit)}
        sx={{ alignItems: "flex-end", mb: "24px" }}
      >
        {(sex === genders.FEMALE
          ? MeasurementsSourceWomenAfterLogin
          : MeasurementsSourceMenAfterLogin
        ).map((inputConfig) => (
          <InputFiledWithSelect
            key={inputConfig.name}
            name={inputConfig.name}
            label={inputConfig.label}
            icon={inputConfig.icon}
            control={control}
            description={inputConfig?.description}
            iconSx={inputConfig?.iconSx ? inputConfig?.iconSx : {}}
            guestCheckIn={false}
          />
        ))}

        <Stack
          sx={{
            mt: "24px",
            width: "100%",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PrimaryButton isLoading={loading} type="submit">
            Get Results
          </PrimaryButton>
          <BtnToast
            text={"Please enter valid values."}
            visibility={showToast}
            setVisibility={setShowToast}
            type="error"
          />
        </Stack>
      </Stack>
      <SelectCheckInDateDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        updateCheckInDate={(value) => {
          setFormValue("checkInDate", value);
        }}
      />
    </MainContainer>
  );
};

export { AfterLoggedInMeasurementsForm };
