import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Button, Collapse, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { MuiOtpInput } from "mui-one-time-password-input";
import PrimaryButton from "../global/button/PrimaryButton";
import ErrorText from "../global/form/ErrorText";
import useApiCall from "../../hooks/useApiCall";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/store/useAuth";
import VerifiedIcon from "@mui/icons-material/Verified";
import NormalCheckBox from "../global/form/NormalCheckBox";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import "yup-phone";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCommon } from "../../hooks/store/useCommon";
import { matchCountryCode } from "../../utils/SmsSeviceChecker";
import ErrorIcon from "@mui/icons-material/Error";

export const verifySchema = yup.object().shape({
  terms: yup
    .bool()
    .oneOf([true], "You must agree to the terms and conditions")
    .required(),
});

export default function OTPFormDialog({
  openDialog,
  successDialog = true,
  navigateTo = false,
}) {
  const navigate = useNavigate();
  const theme = useTheme();
  const { text, palette } = useTheme();
  const { apiCall, error, loading } = useApiCall();
  const { apiCall: apiCall2 } = useApiCall();
  const { setUseDetails, phone, phoneVerified, emailVerified } = useAuth();
  const { settings } = useCommon();
  const [otp, setOtp] = React.useState("");
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [isAffordableNumber, setIsAffordableNumber] = React.useState("pending");

  React.useEffect(() => {
    if (matchCountryCode(phone, settings?.SmsServicesAvailability)) {
      setIsAffordableNumber(true);
    } else {
      setIsAffordableNumber(false);
    }
  }, [settings?.SmsServicesAvailability, phone]);

  const { formState, control } = useForm({
    defaultValues: {},
    resolver: yupResolver(verifySchema),
    mode: "onChange",
  });

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  // code for send/resend otp
  let sendOtp = React.useCallback(async function (resend = false) {
    try {
      if (phone) {
        let response = await apiCall2("post", "/sendOtp", {
          body: { phone: phone },
        });
        return resend && toast(response.message);
      }
    } catch (error) {
      return toast(error.response.data.message, { type: "error" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // code fo verify otp
  const verifyOtp = React.useCallback(
    async function (otp) {
      try {
        if (otp.length !== 4) {
          return setErrorMsg({
            error: true,
            message: "Please provide a valid otp",
          });
        }

        setErrorMsg(false);

        if (Object.keys(formState.errors).length !== 0) return false;

        await apiCall("post", "/verifyOtp", {
          body: { phone: phone, otp },
        });
        setUseDetails({ phoneVerified: true });
        return navigateTo ? navigate(navigateTo) : 1;
      } catch (error) {
        console.log(error);
        return toast(error.response.data.message, { type: "error" });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [phone, formState.errors, navigateTo, apiCall, navigate]
  );

  React.useEffect(() => {
    isAffordableNumber === true && sendOtp();
  }, [sendOtp, isAffordableNumber]);

  // code for verify otp
  const handleComplete = async (finalValue) => {
    try {
      verifyOtp(finalValue);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        PaperProps={{
          style: {
            boxShadow: "none",
          },
        }}
        sx={{
          boxShadow: "none",
          "& .MuiBackdrop-root": {
            background: "rgba(255,255,255,0.7)",
          },
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: { md: "460px" },
              padding: { xs: "12px", sm: "18px" },
              backgroundColor: "#faf9f9",
              borderRadius: "20px",
            },
          },
        }}
        open={openDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <Stack
          sx={{
            position: "relative",
            flexDirection: "column",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* number is affordable and verification pending */}
          <Collapse
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
            in={isAffordableNumber === true && Boolean(!phoneVerified)}
          >
            <Typography
              sx={{
                mt: "24px",
                fontFamily: theme.typography.fontFamily2,
                fontSize: theme.typography.headingBasic,
                color: theme.text.basic,
                fontWeight: theme.typography.headingBasicBold,
              }}
            >
              Verify your phone number
            </Typography>
            <Typography
              sx={{
                mt: "24px",
                color: theme.text.basic,
                fontSize: { xs: "14px", sm: "16px" },
                marginTop: theme.margin.margin3,
                lineHeight: "18px",
                textAlign: "center",
              }}
            >
              Copy the verification code from the SMS I just sent you.
            </Typography>

            <MuiOtpInput
              TextFieldsProps={{ type: "number" }}
              value={otp}
              length={4}
              onChange={handleChange}
              autoFocus
              onComplete={handleComplete}
              sx={{
                mx: "auto",
                mt: "24px",
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                  {
                    display: "none",
                  },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },

                "& .MuiOutlinedInput-input": {
                  padding: { xs: "12px", md: "16px 14px" },
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "16px",
                  backgroundColor: "#ffffff",
                  color: theme.text.primary,
                  fontSize: theme.typography.heading1,
                  fontWeight: theme.typography.heading2Bold,
                },
                "&.MuiOtpInput-Box": {
                  maxWidth: "230px",
                  flexWrap: "nowrap",
                  justifyContent: "space-between",
                  gap: { xs: "12px", sm: "10px", md: "15px" },
                },
              }}
            />

            <ErrorText error={error || errorMsg} />

            <NormalCheckBox
              control={control}
              name={"terms"}
              label={
                <Typography
                  sx={{
                    textAlign: "left",
                    fontSize: { xs: "14px", sm: "16px" },
                  }}
                >
                  I agree to receive body fat check-in reminders at my chosen
                  times from Coach Viva. I can opt out at any time by changing
                  my profile settings. Message & data rates may apply.{" "}
                  <Typography
                    sx={{ color: theme.text.primary, fontWeight: 500 }}
                    component={"a"}
                    href={"https://www.coachviva.com/privacy"}
                    target="_blank"
                  >
                    Privacy Policy
                  </Typography>{" "}
                  &{" "}
                  <Typography
                    component={"a"}
                    sx={{ color: theme.text.primary, fontWeight: 500 }}
                    href={"https://www.coachviva.com/tos"}
                    target="_blank"
                  >
                    Terms
                  </Typography>
                  .
                </Typography>
              }
              defaultChecked
              stackSx={{ mt: "16px" }}
            />

            <PrimaryButton
              isLoading={loading}
              onClick={() => verifyOtp(otp)}
              sx={{
                mt: "16px",
                p: "16px !important",
                width: "100%",
                fontSize: "18px !important",
                maxWidth: "140px",
              }}
            >
              Verify
            </PrimaryButton>

            <Typography
              sx={{
                mt: "18px",
                fontSize: { xs: "14px", sm: "16px" },
              }}
            >
              Didn’t receive an SMS?
            </Typography>
            <Button
              onClick={() => sendOtp(true)}
              variant="text"
              sx={{
                fontSize: { xs: "14px", sm: "16px" },
                textTransform: "unset",
                color: "#30a3fa",
              }}
            >
              Click here to send new code
            </Button>
          </Collapse>

          {/* number is affordable and verification success */}
          <Collapse
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
            in={Boolean(phoneVerified && successDialog)}
          >
            <VerifiedIcon
              sx={{
                fontSize: "50px",
                mt: "40px",
                color: palette.success.main,
              }}
            />
            <Typography
              sx={{
                mt: "24px",
                color: text.basic,
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "22px",
                textAlign: "center",
              }}
            >
              Your phone number has been successfully verified.{" "}
              {!emailVerified && (
                <Typography
                  component={"span"}
                  sx={{
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "22px",
                    textAlign: "center",
                  }}
                >
                  next step would be click on link we have sent on your mail to
                  verify your email.
                </Typography>
              )}
            </Typography>

            <PrimaryButton
              onClick={() => navigate("/login")}
              sx={{
                mt: "30px",
                p: "16px !important",
                width: "100%",
                fontSize: "18px !important",
              }}
            >
              Go to Login
            </PrimaryButton>
          </Collapse>

          {/* number is not affordable */}
          <Collapse in={isAffordableNumber === false}>
            <Stack
              sx={{
                position: "relative",
                flexDirection: "column",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <ErrorIcon
                sx={{
                  fontSize: { xs: "32px", sm: "36px" },
                  color: theme.text.primary,
                }}
              />
              <Typography
                sx={{
                  mt: "24px",
                  color: theme.text.basic,
                  fontSize: { xs: "16px" },
                  marginTop: theme.margin.margin3,
                  lineHeight: "20px",
                  textAlign: "center",
                }}
              >
                Sorry we don't support SMS reminders in your country. We'll send
                email reminders!
              </Typography>

              <PrimaryButton
                component={Link}
                sx={{
                  mt: "24px",
                  p: "16px !important",
                  width: "100%",
                  fontSize: "18px !important",
                  maxWidth: "140px",
                }}
                to="/onboarding"
              >
                Got it
              </PrimaryButton>
            </Stack>
          </Collapse>
        </Stack>
      </Dialog>
    </React.Fragment>
  );
}
