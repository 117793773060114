import { Stack } from "@mui/material";
import React, { useState } from "react";
import DeleteAccountDialog from "../dialog/DeleteAccountDialog";
import ElementLabel from "../../global/form/ElementLabel";
import OutlinedButton from "../../global/button/OutlinedButton";

const DeleteAccount = () => {
  const [openDialog, setOpenDialog] = useState(false);
  return (
    <Stack>
      <ElementLabel
        label={"Delete my account"}
        description={" All your data will be deleted as well"}
      />

      <OutlinedButton
        onClick={() => setOpenDialog(true)}
        sx={{
          mt: { xs: "12px", sm: "16px" },
          width: "212px",
          alignSelf: { xs: "center", sm: "unset" },
        }}
      >
        Delete My Account
      </OutlinedButton>

      <DeleteAccountDialog
        setOpenDialog={setOpenDialog}
        openDialog={openDialog}
      />
    </Stack>
  );
};

export default DeleteAccount;
