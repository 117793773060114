import dayjs from "dayjs";
import * as yup from "yup";
import "yup-phone";

export const TapeReminderSchema = yup.object().shape(
  {
    number: yup
      .string()
      .nullable()
      .when("number", {
        is: (value) => value && value.length > 0,
        then: yup
          .string()
          .phone("Invalid phone number")
          .typeError("Phone number must be a valid number"),
        otherwise: yup.string(),
      }),
    email: yup
      .string()
      .email("Please enter a valid email address")
      .required("Email is required"),
    name: yup
      .string()
      .min(2, "Name must be at least 2 characters")
      .required("Name is required"),
    time: yup
      .string()
      .typeError("Please enter a valid time")
      .required("Please enter a valid time"),
    date: yup
      .date("Date is required")
      .typeError("Please select a valid Date")
      .required("Date is required")
      .test({
        name: "futureDate",
        message: "Please select a future date",
        test: (value) => {
          const minDate = dayjs().add(-1, "day");
          return dayjs(value).isValid() && dayjs(value).isAfter(minDate, "day");
        },
      }),
  },
  [["number", "number"]]
);
