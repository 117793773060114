import React from "react";
import { createSvgIcon } from "@mui/material";
export default function PendingIcon(props) {
  const SvgIcon = createSvgIcon(
    <>
      <g id="__id1949_sv5i6jabw">
        <path
          d="M 250 480.082031 C 123.132812 480.082031 19.921875 376.867188 19.921875 250 C 19.921875 123.132812 123.132812 19.921875 250 19.921875 C 376.867188 19.921875 480.078125 123.132812 480.078125 250 C 480.078125 376.867188 376.867188 480.082031 250 480.082031 Z M 480.351562 152.6875 C 467.757812 122.914062 449.734375 96.179688 426.777344 73.222656 C 403.820312 50.265625 377.085938 32.242188 347.3125 19.648438 C 316.488281 6.609375 283.746094 0 250 0 C 216.253906 0 183.511719 6.609375 152.6875 19.648438 C 122.914062 32.242188 96.179688 50.265625 73.222656 73.222656 C 50.265625 96.179688 32.242188 122.914062 19.652344 152.6875 C 6.613281 183.511719 0 216.253906 0 250 C 0 283.746094 6.613281 316.488281 19.652344 347.3125 C 32.242188 377.085938 50.265625 403.820312 73.222656 426.777344 C 96.179688 449.734375 122.914062 467.757812 152.6875 480.351562 C 183.511719 493.390625 216.253906 500 250 500 C 283.746094 500 316.488281 493.390625 347.3125 480.351562 C 377.085938 467.757812 403.820312 449.734375 426.777344 426.777344 C 449.734375 403.820312 467.757812 377.085938 480.351562 347.3125 C 493.390625 316.488281 500 283.746094 500 250 C 500 216.253906 493.390625 183.511719 480.351562 152.6875"
          fill="rgb(139, 137, 137)"
        />
        <path
          d="M 369.820312 334.398438 L 259.960938 245.253906 L 259.960938 127.019531 C 259.960938 121.519531 255.5 117.0625 250 117.0625 C 244.5 117.0625 240.039062 121.519531 240.039062 127.019531 L 240.039062 250 C 240.039062 253.003906 241.394531 255.84375 243.722656 257.734375 L 357.269531 349.867188 C 359.117188 351.363281 361.335938 352.09375 363.539062 352.09375 C 366.4375 352.09375 369.3125 350.832031 371.28125 348.40625 C 374.746094 344.136719 374.09375 337.863281 369.820312 334.398438"
          fill="rgb(139, 137, 137)"
        />
      </g>
    </>
  );
  return (
    <SvgIcon
      width="24"
      height="24"
      fill="none"
      viewBox="0.0 0.0 500.0 500.0"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        "&.MuiSvgIcon-root": {
          width: "unset",
          height: "unset",
          ...props.sx,
        },
      }}
      {...props}
    />
  );
}
