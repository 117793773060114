import React from "react";
import MainContainer from "../components/global/container/MainContainer";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import TabComponent from "../components/settings/TabComponent";
import TechSupportForm from "../components/support/TechSupportForm";
import Coaching from "../components/support/Coaching";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/store/useAuth";
import { matchCountryCode } from "../utils/SmsSeviceChecker";
import { useCommon } from "../hooks/store/useCommon";

const Help = () => {
  const theme = useTheme();
  const { phone, CloseLeadStatus } = useAuth();
  const { settings } = useCommon();
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(
    location.pathname === "/tech-support" ? 0 : 1
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue) navigate("/coaching");
    else navigate("/tech-support");
  };

  return (
    <MainContainer>
      <Typography
        sx={{
          mt: "12px",
          fontSize: { xs: "12px", sm: "16px" },
          color: theme.text.primary,
          fontWeight: 600,
          textTransform: "uppercase",
        }}
      >
        {phone &&
        matchCountryCode(phone, settings?.SmsServicesAvailability) &&
        CloseLeadStatus !== "BBB Customer"
          ? "Get Help"
          : "Tech Support"}
      </Typography>

      {phone &&
        matchCountryCode(phone, settings?.SmsServicesAvailability) &&
        CloseLeadStatus !== "BBB Customer" && (
          <>
            <Typography
              sx={{
                mt: "16px",
                fontSize: { xs: "14px", sm: "16px" },
                fontWeight: 600,
              }}
            >
              Issue type?
            </Typography>
            <TabComponent
              value={value}
              handleChange={handleChange}
              tabItem={["Tech", "Coaching"]}
              tabSx={{
                ml: 0,
                width: "100%",
                borderRadius: "120px",
                boxShadow: "unset",
                minHeight: "unset",
                "&.MuiTabs-root": {
                  mt: "8px",
                },
                "& .MuiTab-root": {
                  fontWeight: 200,
                  zIndex: "11",
                },
                "& .MuiButtonBase-root.MuiTab-root": {
                  minHeight: "unset",
                },
                border: "1px solid",
                borderColor: "input.border",
                "& .MuiTabs-indicator": {
                  height: "100%",
                  backgroundColor: "primary.main",
                },

                "& .MuiTab-root.Mui-selected": {
                  color: "#fff",
                  zIndex: "10",
                },
              }}
              buttonSx={{
                padding: "10px",
                fontSize: { xs: "14px", sm: "18px" },
              }}
            />
          </>
        )}
      {value === 0 && <TechSupportForm />}
      {value === 1 && <Coaching />}
    </MainContainer>
  );
};

export default Help;
