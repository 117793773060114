import { create } from "zustand";

export const useCommon = create((set) => ({
  // 1. code for hide/show navbar visibility
  navbarVisibility: true,
  openUpdateCheckInDialog: false,
  updateCheckInId: undefined,
  updateRecordCurrentValue: {},
  totalCheckInLength: 0,
  suggestionDialog: false,
  settings: {},
  isProgressCheckIn: false,
  fromTrigger: false,

  setNavbarVisibility(navbarVisibility = true) {
    set(() => ({
      navbarVisibility,
    }));
  },

  setOpenUpdateCheckInDialog(
    openUpdateCheckInDialog = false,
    updateCheckInId = undefined,
    updateRecordCurrentValue = {},
    totalCheckInLength = 0
  ) {
    set(() => ({
      openUpdateCheckInDialog,
      updateCheckInId,
      updateRecordCurrentValue,
      totalCheckInLength,
    }));
  },

  setOpenSuggestionDialog(suggestionDialog = false) {
    set(() => ({
      suggestionDialog,
    }));
  },
  setSettings(settings) {
    set(() => ({
      settings,
    }));
  },

  // for manage amplitude event("Progress_CheckIn") at new check-in by user after login
  setIsProgressCheckIn(isProgressCheckIn = false) {
    set(() => ({ isProgressCheckIn }));
  },

  setFromTrigger(fromTrigger = false) {
    set(() => ({ fromTrigger }));
  },
}));
