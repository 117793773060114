import { useTheme } from "@emotion/react";
import { alpha, Box, Typography } from "@mui/material";
import React from "react";

const UnderfatContent = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        marginTop: theme.margin.margin1,
        padding: theme.padding.padding2,
        background: alpha(theme.palette.background.primary, 0.1),
        borderRadius: "16px",
      }}
    >
      <Typography
        sx={{
          fontFamily: theme.typography.fontFamily2,
          fontSize: theme.typography.headingMain,
          color: theme.text.primary,
          fontWeight: theme.typography.headingMainBold,
        }}
      >
        Gain weight
      </Typography>
      <Typography
        sx={{
          color: theme.text.basic,
          fontSize: theme.typography.heading1,
          marginTop: theme.margin.margin3,
        }}
      >
        But in a way where you’re gaining fat and lean mass in similar ratios,
        so you don’t end up in the Unhealthy range.
      </Typography>
      <Typography
        sx={{
          color: theme.text.basic,
          fontSize: theme.typography.heading1,
          marginTop: theme.margin.margin3,
        }}
      >
        Resources that may be helpful:
      </Typography>

      <Box
        component={"ul"}
        sx={{
          listStylePosition: "outside",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          justifyContent: "center",
          marginLeft: "20px",
        }}
      >
        <Typography
          component={"li"}
          sx={{
            color: theme.text.basic,
            fontSize: theme.typography.heading1,
            marginTop: theme.margin.margin3,
          }}
        >
          <a
            target="_blank"
            href="https://www.nhs.uk/live-well/healthy-weight/managing-your-weight/healthy-ways-to-gain-weight/"
            rel="noreferrer"
          >
            National Health Service
          </a>{" "}
          to ground you on why this is important and how to filter extreme
          advice (avoid their “do nots“)
        </Typography>
        <Typography
          component={"li"}
          sx={{
            color: theme.text.basic,
            fontSize: theme.typography.heading1,
            marginTop: theme.margin.margin3,
          }}
        >
          <a
            target="_blank"
            href="https://www.nerdfitness.com/blog/the-beginners-guide-to-building-muscle-and-strength/"
            rel="noreferrer"
          >
            Nerd Fitness Guide
          </a>{" "}
          on how to build muscle while gaining weight, so you don‘t end up in
          the Unhealthy range.
        </Typography>
      </Box>
    </Box>
  );
};

export default UnderfatContent;
