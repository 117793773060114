import React, { useCallback, useState } from "react";
import MainContainer from "../components/global/container/MainContainer";
import { Collapse, Stack, Typography } from "@mui/material";
import BrandIcon from "../icons/BrandIcon";
import { useTheme } from "@emotion/react";
import PrimaryButton from "../components/global/button/PrimaryButton";
import { useSearchParams } from "react-router-dom";
import useUpdateData from "../hooks/useUpdateData";
import { toast } from "react-toastify";

const UnSubscribeMail = () => {
  const { text } = useTheme();
  const { updateData, loading } = useUpdateData();
  const [query] = useSearchParams();
  const token = query.get("token");
  const [unSubscribe, setUnSubscribe] = useState(false);

  const updateMailSubscription = useCallback(
    async function () {
      try {
        await updateData(
          "/reminder",
          { getEmails: unSubscribe, getMissedCheckInEmails: unSubscribe },
          token
        );
        toast(
          unSubscribe
            ? "Welcome back! You have successfully re-subscribed to Coach Viva's emails."
            : "You have successfully unsubscribed from Coach Viva's emails."
        );
        return setUnSubscribe((prev) => !prev);
      } catch (error) {
        console.error(error);
      }
    },
    [unSubscribe, token, updateData]
  );
  return (
    <MainContainer showNavbar={false}>
      <Stack
        sx={{
          minHeight: "100dvh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <BrandIcon
          sx={{
            zIndex: 99,
            cursor: "pointer",
            width: { xs: "42px", sm: "48px" },
            height: { xs: "42px", sm: "48px" },
          }}
        />
        <Typography
          sx={{
            fontSize: "24px",
            cursor: "pointer",
            textDecoration: "none",
            color: text.basic,
          }}
        >
          Coach Viva
        </Typography>

        {/* content before unsubscription */}
        <Collapse in={!unSubscribe}>
          <Typography
            sx={{
              mt: "24px",
              fontSize: "20px",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            You will no longer receive email reminders to do your body fat
            check-ins.
          </Typography>

          <Typography
            sx={{
              mt: "8px",
              fontSize: "16px",
              fontWeight: "400",
              textAlign: "center",
            }}
          >
            Are you sure you will remember without these reminders?
          </Typography>
        </Collapse>

        {/* content after unsubscription */}
        <Collapse in={unSubscribe}>
          <Typography
            sx={{
              mt: "24px",
              fontSize: "20px",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            You will no longer receive body fat check-in email reminders.
          </Typography>
          <Typography
            sx={{
              mt: "8px",
              fontSize: "16px",
              fontWeight: "400",
              textAlign: "center",
            }}
          >
            If this was by mistake, you can re-subscribe.
          </Typography>
        </Collapse>

        <PrimaryButton
          onClick={updateMailSubscription}
          sx={{ mt: "20px" }}
          isLoading={loading}
          disabled={!token}
        >
          {unSubscribe ? "Re-subscribe" : "Yes, unsubscribe"}
        </PrimaryButton>
      </Stack>
    </MainContainer>
  );
};

export default UnSubscribeMail;
