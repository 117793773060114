import { useState } from "react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../services/firebase";
import { fileUploadStatus } from "../constants/fileUploadStatus";

const useFileUpload = () => {
  const [progress, setProgress] = useState([]);
  const [uploading, setUploading] = useState(fileUploadStatus.NOT_STARTED); // not-started, in-progress, success, error
  const [downloadURLs, setDownloadURLs] = useState([]);

  const handleUpload = async (formFiles, folderName) => {
    try {
      let files = [];

      // 1. check user select multiple or single file
      if (Array.isArray(formFiles)) files = formFiles;
      else files = [formFiles];

      setUploading(fileUploadStatus.IN_PROGRESS);

      // 2. configure upload tasks
      const uploadTasks = files.map((file) => {
        const storageRef = ref(storage, `${folderName}/${file.name}`);
        return uploadBytesResumable(storageRef, file);
      });

      // 3. start and monitor upload progress
      uploadTasks.forEach((uploadTask, index) => {
        uploadTask.on("state_changed", (snapshot) => {
          const currentProgress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress((prevProgress) => {
            const updatedProgress = [...prevProgress];
            updatedProgress[index] = {
              fileName: files[index].name,
              progress: currentProgress,
            };
            return updatedProgress;
          });
        });
      });

      await Promise.all(uploadTasks);

      const urls = await Promise.all(
        uploadTasks.map((task) => getDownloadURL(task.snapshot.ref))
      );
      setDownloadURLs(urls);
      setUploading(fileUploadStatus.SUCCESS);
      return urls;
    } catch (error) {
      setUploading(fileUploadStatus.ERROR);
      throw new Error(`file upload failed : ${error}`);
    }
  };

  return {
    progress,
    uploading,
    downloadURLs,
    handleUpload,
  };
};

export default useFileUpload;
