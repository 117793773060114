import * as React from "react";
import Dialog from "@mui/material/Dialog";
import UpdateAlert from "./UpdateAlert";
import { useAuth } from "../../../hooks/store/useAuth";
import { Collapse } from "@mui/material";
import EmailUpdateDialog from "./EmailUpdateDialog";
import PhoneUpdateDialog from "./PhoneUpdateDialog";
export default function PhoneEmailDialog({
  title,
  description,
  openDialog,
  setOpenDialog,
  formData,
}) {
  // const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [openContainer, setOpenContainer] = React.useState("update alert"); // 1.update alert, 2.email update dialog, 3.phone update dialog
  const { email } = useAuth();

  const handleClose = () => {
    setOpenDialog(false);
  };

  const openNextContainer = () => {
    if (formData.email !== email) {
      setOpenContainer("email update dialog");
    } else {
      setOpenContainer("phone update dialog");
    }
  };

  return (
    <React.Fragment>
      <Dialog
        PaperProps={{
          style: {
            boxShadow: "none",
          },
        }}
        sx={{
          boxShadow: "none",
          "& .MuiBackdrop-root": {
            background: "rgba(255,255,255,0.7)",
          },
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: { md: "460px" },
              padding: { xs: "12px", sm: "24px" },
              backgroundColor: "#faf9f9",
              borderRadius: "20px",
            },
          },
        }}
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Collapse
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
          in={openContainer === "update alert"}
        >
          <UpdateAlert
            title={title}
            description={description}
            handleClose={handleClose}
            openNextContainer={openNextContainer}
          />
        </Collapse>

        <Collapse
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
          in={openContainer === "email update dialog"}
        >
          <EmailUpdateDialog
            formData={formData}
            openContainer={openContainer}
            setOpenContainer={setOpenContainer}
            handleClose={handleClose}
          />
        </Collapse>

        <Collapse
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
          in={openContainer === "phone update dialog"}
        >
          <PhoneUpdateDialog
            formData={formData}
            openContainer={openContainer}
            handleClose={handleClose}
          />
        </Collapse>
      </Dialog>
    </React.Fragment>
  );
}
