import { Button, Dialog, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { MuiOtpInput } from "mui-one-time-password-input";
import { toast } from "react-toastify";
import useApiCall from "../../hooks/useApiCall";
import ErrorText from "../global/form/ErrorText";
import PrimaryButton from "../global/button/PrimaryButton";
import useSetData from "../../hooks/useSetData";
import useUpdateData from "../../hooks/useUpdateData";
import ErrorIcon from "@mui/icons-material/Error";
import { matchCountryCode } from "../../utils/SmsSeviceChecker";
import { useCommon } from "../../hooks/store/useCommon";

const VerifyMobileDialog = ({
  openDialog,
  setOpenDialog,
  formData,
  callBack,
}) => {
  const { text } = useTheme();
  const theme = useTheme();
  const { loading: setDataLoading, setData } = useSetData();
  const { loading: updateLoading, updateData } = useUpdateData();
  const { apiCall, error, loading } = useApiCall();
  const { apiCall: apiCall2 } = useApiCall();
  const [otp, setOtp] = React.useState("");
  const [errorMsg, setErrorMsg] = React.useState(false);
  const { settings } = useCommon();
  const [isAffordableNumber, setIsAffordableNumber] = React.useState("pending");

  React.useEffect(() => {
    if (matchCountryCode(formData.number, settings?.SmsServicesAvailability)) {
      setIsAffordableNumber(true);
    } else {
      setIsAffordableNumber(false);
    }
  }, [settings?.SmsServicesAvailability, formData.number]);

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  // code for send/resend otp
  const sendOtp = React.useCallback(async function (resend = false) {
    try {
      if (formData.number) {
        const response = await apiCall2("post", "/sendOtpForTapeReminder", {
          body: { phone: formData.number },
        });
        return resend && toast(response.message);
      }
    } catch (error) {
      return toast(error.response.data.message, { type: "error" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // code fo verify otp
  async function verifyOtp(otp) {
    try {
      if (otp.length !== 4) {
        return setErrorMsg({
          error: true,
          message: "Please provide a valid otp",
        });
      }

      setErrorMsg(false);
      let response = await apiCall("post", "/verifyOtpForTapeReminder", {
        body: { phone: formData.number, otp },
      });

      if (response.success) {
        // update phoneVerification status of user record
        if (formData?.userId) {
          await updateData("/users", { phoneVerified: true }, formData.userId);
        }

        const data = formData;
        delete data.userId;
        await setData("/tapeReminder", data, formData.recordId);

        callBack();
        return setOpenDialog(false);
      } else {
        return toast(response.message, { type: "error" });
      }
    } catch (error) {
      console.error(error);
      setErrorMsg({
        error: true,
        message: "Please provide a valid otp",
      });
      return toast(error.response.data.message, { type: "error" });
    }
  }

  React.useEffect(() => {
    isAffordableNumber === true && sendOtp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAffordableNumber]);

  // code for verify otp
  const handleComplete = async (finalValue) => {
    try {
      verifyOtp(finalValue);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Dialog
        PaperProps={{
          style: {
            boxShadow: "none",
          },
        }}
        sx={{
          boxShadow: "none",
          "& .MuiBackdrop-root": {
            background: "rgba(255,255,255,0.7)",
          },
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: { md: "460px" },
              padding: { xs: "12px", sm: "18px" },
              backgroundColor: "#faf9f9",
              borderRadius: "20px",
            },
          },
        }}
        open={openDialog}
        aria-labelledby="responsive-dialog-title"
      >
        {isAffordableNumber === true && (
          <Stack sx={{ alignItems: "center" }}>
            <Typography
              sx={{
                mt: "24px",
                fontFamily: theme.typography.fontFamily2,
                fontSize: theme.typography.headingBasic,
                color: theme.text.basic,
                fontWeight: theme.typography.headingBasicBold,
              }}
            >
              Enter OTP
            </Typography>
            <Typography
              sx={{
                mt: "24px",
                color: text.basic,
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: { xs: "14px", sm: "16px" },
                marginTop: theme.margin.margin3,
                lineHeight: "20px",
                textAlign: "center",
              }}
            >
              We have sent a verification code to your mobile number:{" "}
              <Typography
                component={"span"}
                sx={{
                  color: text.primary,
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: { xs: "14px", sm: "16px" },
                  marginTop: theme.margin.margin3,
                  lineHeight: "20px",
                  textAlign: "center",
                }}
              >
                {formData.number}
              </Typography>
              . Please check your phone for the OTP.
            </Typography>

            <MuiOtpInput
              TextFieldsProps={{ type: "number" }}
              value={otp}
              length={4}
              onChange={handleChange}
              autoFocus
              onComplete={handleComplete}
              sx={{
                mx: "auto",
                mt: "24px",
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                  {
                    display: "none",
                  },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },

                "& .MuiOutlinedInput-input": {
                  padding: { xs: "12px", md: "16px 14px" },
                },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "16px",
                  backgroundColor: "#ffffff",
                  color: theme.text.primary,
                  fontSize: theme.typography.heading1,
                  fontWeight: theme.typography.heading2Bold,
                },
                "&.MuiOtpInput-Box": {
                  maxWidth: "230px",
                  flexWrap: "nowrap",
                  justifyContent: "space-between",
                  gap: { xs: "12px", sm: "10px", md: "15px" },
                },
              }}
            />

            <ErrorText error={error || errorMsg} />

            <PrimaryButton
              disabled={true}
              isLoading={loading || setDataLoading || updateLoading}
              sx={{
                mt: "30px",
                p: "16px !important",
                width: "100%",
                fontSize: "18px !important",
              }}
            >
              Verify
            </PrimaryButton>

            <Typography
              sx={{
                mt: "18px",
                fontSize: { xs: "14px", sm: "16px" },
              }}
            >
              Didn’t receive an SMS?
            </Typography>
            <Button
              onClick={() => sendOtp(true)}
              variant="text"
              sx={{
                fontSize: { xs: "14px", sm: "16px" },
                textTransform: "unset",
                color: "#30a3fa",
              }}
            >
              Click here to send new code
            </Button>
          </Stack>
        )}
        {isAffordableNumber === false && (
          <Stack
            sx={{
              position: "relative",
              flexDirection: "column",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <ErrorIcon
              sx={{
                fontSize: { xs: "32px", sm: "36px" },
                color: theme.text.primary,
              }}
            />
            <Typography
              sx={{
                mt: "24px",
                color: theme.text.basic,
                fontSize: { xs: "16px" },
                marginTop: theme.margin.margin3,
                lineHeight: "20px",
                textAlign: "center",
              }}
            >
              Sorry we don't support SMS reminders in your country. We'll send
              email reminders!
            </Typography>

            <PrimaryButton
              onClick={() => {
                callBack();
                return setOpenDialog(false);
              }}
              sx={{
                mt: "24px",
                p: "16px !important",
                width: "100%",
                fontSize: "18px !important",
                maxWidth: "140px",
              }}
              to="/onboarding"
            >
              Got it
            </PrimaryButton>
          </Stack>
        )}
      </Dialog>
    </>
  );
};

export default VerifyMobileDialog;
