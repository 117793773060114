import { Collapse, Stack, Typography, alpha, useTheme } from "@mui/material";
import { MuiFileInput } from "mui-file-input";
import React from "react";
import { Controller } from "react-hook-form";
import ErrorText from "./ErrorText";
import AttachFileRoundedIcon from "@mui/icons-material/AttachFileRounded";

export const FileUpload = ({
  control,
  name,
  label = "",
  componentStyle = {},
  placeholder = "",
  errorSx = {},
  multiple = true,
}) => {
  const theme = useTheme();
  const style = {
    borderRadius: "18px",
    border: "2px solid",
    borderColor: "input.border",
    backgroundColor: alpha(theme.palette.input.border, 0.2),
    "& .MuiOutlinedInput-root ": {
      fontSize: { xs: "14px", sm: "16px" },
      fontWeight: 200,
    },
    "& fieldset": {
      borderColor: "transparent",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderWidth: "0px",
      borderColor: "transparent",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "& .MuiFileInput-placeholder": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "red",
      textAlign: "center",
    },
  };
  return (
    <Stack sx={{ width: "100%", mt: "28px", ...componentStyle }}>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Stack>
            <Stack
              sx={{
                width: "100%",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <Typography
                sx={{
                  color: theme.text.basic,
                  fontSize: { xs: "14px", sm: "16px" },
                  fontWeight: theme.typography.heading1Bold,
                  textWrap: "nowrap",
                  whiteSpace: "pre",
                  lineHeight: "16px",
                }}
              >
                {label}
              </Typography>
              <MuiFileInput
                InputProps={{
                  inputProps: {
                    accept:
                      "video/*,image/png,image/jpeg,image/jpg,image/webp,image/HEIC",
                  },
                  startAdornment: <AttachFileRoundedIcon />,
                }}
                placeholder={placeholder}
                multiple={multiple}
                sx={style}
                {...field}
              />
            </Stack>
            <Collapse in={Boolean(error)}>
              <ErrorText error={error} errorSx={errorSx} />
            </Collapse>
          </Stack>
        )}
      />
    </Stack>
  );
};
