import { Box, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { useFirstCheckIn } from "../../hooks/store/useFirstCheckIn";

const StatusBar = ({ result, bodyStatus }) => {
  const theme = useTheme();
  const { gender } = useFirstCheckIn();
  let { index } = bodyStatus;

  function getPointerPosition(
    maxValue,
    minValue,
    currentValue = Number(result.bodyFatPercentage)
  ) {
    if (maxValue <= minValue || currentValue <= minValue) {
      return 0; // To avoid division by zero or negative values
    }

    let position = ((currentValue - minValue) / (maxValue - minValue)) * 100;
    return position > 100 ? 100 : position < 0 ? 0 : position;
  }

  return (
    <Box
      sx={{
        mt: theme.margin.margin1,
        padding: { xs: "16px", sm: "18px" },
        backgroundColor: "background.grey",
        borderRadius: "16px",
      }}
    >
      <Typography
        sx={{ fontSize: { xs: "10px", sm: "12px" }, color: theme.text.info }}
      >
        Body Fat
      </Typography>
      <Typography
        sx={{
          fontSize: "30.2px",
          lineHeight: "30px",
          color: theme.text.basic,
          fontFamily: theme.typography.fontFamily2,
          fontWeight: 600,
        }}
      >
        {result.bodyFatPercentage}%
      </Typography>

      <Stack
        sx={{
          mt: "44px",
          display: "flex",
          flexDirection: "row",
          width: "100%",
          gap: "6px",
        }}
      >
        <Stack sx={{ width: "30%" }}>
          <Box
            sx={{
              height: "12px",
              borderRadius: "20px",
              backgroundColor: "#ff5757",
              position: "relative",
            }}
          >
            <Stack sx={{ display: index !== 0 ? "none" : "flex" }}>
              <Typography
                sx={{
                  position: "absolute",
                  top: "-345%",
                  left: `${getPointerPosition(
                    gender === "Male" ? 7.9 : 17.9,
                    0
                  )}%`,
                  transform: "translateX(-50%)",
                  fontSize: { xs: "12px", sm: "14px" },
                  lineHeight: "14px",
                  color: theme.text.basic,
                  fontWeight: 400,
                }}
              >
                Underfat
              </Typography>
              <Box
                sx={{
                  position: "absolute",
                  top: "0%",
                  left: `${getPointerPosition(
                    gender === "Male" ? 7.9 : 17.9,
                    0
                  )}%`,
                  transform: "translate(-50%,-130%) rotate(-45deg)",
                  width: "16px",
                  height: "16px",
                  backgroundColor: theme.text.basic,
                  borderTopRightRadius: "50%",
                  borderTopLeftRadius: "50%",
                  borderBottomRightRadius: "50%",
                }}
              ></Box>
            </Stack>
          </Box>
          <Typography
            sx={{
              fontSize: theme.typography.heading2,
              textAlign: "right",
              transform: "translateX(12px)",
            }}
          >
            {gender === "Male" ? "8%" : "18%"}
          </Typography>
        </Stack>

        <Stack sx={{ width: "30%" }}>
          <Box
            sx={{
              height: "12px",
              borderRadius: "20px",
              backgroundColor: "#7ed957",
              position: "relative",
            }}
          >
            <Stack sx={{ display: index !== 1 ? "none" : "flex" }}>
              <Typography
                sx={{
                  position: "absolute",
                  top: "-345%",
                  left: `${getPointerPosition(
                    gender === "Male" ? 14.9 : 24.9,
                    gender === "Male" ? 8 : 18
                  )}%`,
                  transform: "translateX(-50%)",
                  fontSize: { xs: "12px", sm: "14px" },
                  lineHeight: "14px",
                  color: theme.text.basic,
                  fontWeight: 400,
                }}
              >
                Healthy
              </Typography>
              <Box
                sx={{
                  position: "absolute",
                  top: "0%",
                  left: `${getPointerPosition(
                    gender === "Male" ? 14.9 : 24.9,
                    gender === "Male" ? 8 : 18
                  )}%`,
                  transform: "translate(-50%,-130%) rotate(-45deg)",
                  width: "16px",
                  height: "16px",
                  backgroundColor: theme.text.basic,
                  borderTopRightRadius: "50%",
                  borderTopLeftRadius: "50%",
                  borderBottomRightRadius: "50%",
                }}
              ></Box>
            </Stack>
          </Box>

          <Typography
            sx={{
              fontSize: theme.typography.heading2,
              textAlign: "right",
              transform: "translateX(12px)",
            }}
          >
            {gender === "Male" ? "15%" : "25%"}
          </Typography>
        </Stack>

        <Stack sx={{ width: "20%" }}>
          <Box
            sx={{
              height: "12px",
              borderRadius: "20px",
              backgroundColor: "#ffbd59",
              position: "relative",
            }}
          >
            <Stack sx={{ display: index !== 2 ? "none" : "flex" }}>
              <Typography
                sx={{
                  position: "absolute",
                  top: "-345%",
                  left: `${getPointerPosition(
                    gender === "Male" ? 20 : 30,
                    gender === "Male" ? 15 : 25
                  )}%`,
                  transform: "translateX(-50%)",
                  fontSize: { xs: "12px", sm: "14px" },
                  lineHeight: "14px",
                  color: theme.text.basic,
                  fontWeight: 400,
                }}
              >
                Acceptable
              </Typography>
              <Box
                sx={{
                  position: "absolute",
                  top: "0%",
                  left: `${getPointerPosition(
                    gender === "Male" ? 20 : 30,
                    gender === "Male" ? 15 : 25
                  )}%`,
                  transform: "translate(-50%,-130%) rotate(-45deg)",
                  width: "16px",
                  height: "16px",
                  backgroundColor: theme.text.basic,
                  borderTopRightRadius: "50%",
                  borderTopLeftRadius: "50%",
                  borderBottomRightRadius: "50%",
                }}
              ></Box>
            </Stack>
          </Box>

          <Typography
            sx={{
              fontSize: theme.typography.heading2,
              textAlign: "right",
              transform: "translateX(12px)",
            }}
          >
            {gender === "Male" ? "20%" : "30%"}
          </Typography>
        </Stack>

        <Stack sx={{ width: "30%" }}>
          <Box
            sx={{
              height: "12px",
              borderRadius: "20px",
              backgroundColor: "#ff5757",
              position: "relative",
            }}
          >
            <Stack sx={{ display: index !== 3 ? "none" : "flex" }}>
              <Typography
                sx={{
                  position: "absolute",
                  top: "-345%",
                  left: `${getPointerPosition(
                    gender === "Male" ? 100 : 100,
                    gender === "Male" ? 21 : 31
                  )}%`,
                  transform: "translateX(-50%)",
                  fontSize: { xs: "12px", sm: "14px" },
                  lineHeight: "14px",
                  color: theme.text.basic,
                  fontWeight: 400,
                }}
              >
                Unhealthy
              </Typography>
              <Box
                sx={{
                  position: "absolute",
                  top: "0%",
                  left: `${getPointerPosition(
                    50,
                    gender === "Male" ? 21 : 31
                  )}%`,
                  transform: "translate(-50%,-130%) rotate(-45deg)",
                  width: "16px",
                  height: "16px",
                  backgroundColor: theme.text.basic,
                  borderTopRightRadius: "50%",
                  borderTopLeftRadius: "50%",
                  borderBottomRightRadius: "50%",
                }}
              ></Box>
            </Stack>
          </Box>
        </Stack>
      </Stack>

      {(index === 2 || index === 3) && (
        <Stack sx={{ mt: "18px", color: theme.text.grey }}>
          <Typography
            sx={{
              fontSize: { xs: "12px", sm: "14px" },
              lineHeight: { xs: "18px", sm: "20px" },
            }}
          >
            <Typography
              component={"span"}
              sx={{
                fontSize: { xs: "12px", sm: "14px" },
                fontWeight: "700",
                lineHeight: { xs: "18px", sm: "20px" },
              }}
            >
              Accuracy?
            </Typography>{" "}
            No method is 100% accurate. Even DEXA scans have an error of
            2.5-3.5%. As long as you measure at same time every week (consistent
            input) and see BF% decreasing using this calculator (consistent
            math), you‘re making progress. Trends &gt; Accuracy.
          </Typography>
        </Stack>
      )}
    </Box>
  );
};

export default StatusBar;
