import dayjs from "dayjs";
require("dayjs/plugin/customParseFormat");
dayjs.extend(require("dayjs/plugin/customParseFormat"));

export const formatFirebaseTimestamp = (
  timestamp,
  dateFormat = "DD/MM/YYYY"
) => {
  // Convert Timestamp to Date
  const date = timestamp * 1000;
  return dayjs(date).format(dateFormat);
};

export const findDayDifferenceBetweenDate = (date1, date2) => {
  const currentCheckInDate = dayjs(
    formatFirebaseTimestamp(date1),
    "DD/MM/YYYY"
  );
  const previousCheckInDate = dayjs(
    formatFirebaseTimestamp(date2),
    "DD/MM/YYYY"
  );

  return currentCheckInDate.diff(previousCheckInDate, "day");
};
