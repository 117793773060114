import { useState } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
  limit as firestoreLimit,
} from "firebase/firestore";
import { db } from "../services/firebase";

const useFindData = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setData] = useState(null);

  const findData = async (
    collectionPath,
    fieldName,
    operator,
    value,
    orderByField = null,
    orderByDirection = "asc",
    limit = null
  ) => {
    try {
      setLoading(true);
      setError(false);
      setErrorMessage("");

      // Query data in Firestore
      let q = query(
        collection(db, collectionPath),
        where(fieldName, operator, value)
      );

      // Add sorting if orderByField is provided
      if (orderByField) {
        q = query(q, orderBy(orderByField, orderByDirection));
      }

      // Add limiting if limit is provided
      if (limit) {
        q = query(q, firestoreLimit(limit));
      }

      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setData(null);
      } else {
        const foundData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setData(foundData);
        return foundData;
      }
    } catch (error) {
      console.error(error);
      setError(true);
      setErrorMessage(error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const findDataById = async (collectionPath, id) => {
    try {
      setLoading(true);
      setError(false);
      setErrorMessage("");

      // Get document by ID in Firestore
      const docRef = doc(db, collectionPath, id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const foundData = {
          id: docSnap.id,
          ...docSnap.data(),
        };
        setData(foundData);
        return foundData;
      } else {
        setData(null);
        return null;
      }
    } catch (error) {
      console.error(error);
      setError(true);
      setErrorMessage(error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const findAllData = async (collectionPath) => {
    try {
      setLoading(true);
      setError(false);
      setErrorMessage("");

      const querySnapshot = await getDocs(collection(db, collectionPath));

      if (querySnapshot.empty) {
        setData(null);
      } else {
        const allData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setData(allData);
        return allData;
      }
    } catch (error) {
      console.error(error);
      setError(true);
      setErrorMessage(error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    errorMessage,
    data,
    findData,
    findDataById,
    findAllData,
  };
};

export default useFindData;
