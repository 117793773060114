import {
  Box,
  Collapse,
  Stack,
  TextField,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { useFirstCheckIn } from "../../../hooks/store/useFirstCheckIn";
import { useAuth } from "../../../hooks/store/useAuth";
import ErrorText from "./ErrorText";

export const InputFiledWithSelect = ({
  name,
  label,
  control,
  icon,
  guestCheckIn = true,
  inputSx = {},
  labelSx = {},
  disabled = false,
  description,
  iconSx,
  hideInput = false,
}) => {
  const { text, palette } = useTheme();
  const theme = useTheme();

  // unitPreference for first check in as guest
  const { unitPreference: guestUnitPreference } = useFirstCheckIn();
  const { unitPreference } = useAuth();
  return (
    !hideInput && (
      <Stack
        component={"label"}
        htmlFor={name}
        sx={{
          mt: { xs: "12px", sm: "20px" },
          width: "100%",
          display: "grid",
          gridTemplateColumns: ".3fr .7fr",
          gridTemplateRows: "1fr",
          gap: { xs: "12px", sm: "20px" },
          background: alpha(palette.background.primary, 0.15),
          paddingInline: { xs: "16px", sm: "24px" },
          paddingBlock: "16px",
          borderRadius: { xs: "30px", sm: "36px" },
          cursor: "pointer",
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${icon})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
            ...iconSx,
          }}
        ></Box>
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Stack
              sx={{
                width: "100%",
                height: "100%",
                justifyContent: "space-between",
              }}
            >
              <Stack sx={{ height: "100%" }}>
                <Typography
                  sx={{
                    color: text.secondary,
                    fontSize: theme.typography.heading1,
                    fontWeight: theme.typography.heading1Bold,
                    fontStyle: "normal",
                    lineHeight: "22px",
                    ...labelSx,
                  }}
                >
                  {label}
                </Typography>
                <Typography
                  sx={{
                    mt: "2px",
                    color: text.secondary,
                    fontSize: theme.typography.heading2,
                    fontWeight: 400,
                    lineHeight: "14px",
                  }}
                >
                  {description}
                </Typography>
              </Stack>

              <Stack sx={{ mt: "20px" }}>
                <TextField
                  id={name}
                  disabled={disabled}
                  fullWidth
                  type="number"
                  onChange={onChange}
                  value={value}
                  error={Boolean(error)}
                  InputProps={{
                    onWheel: (e) => e.target.blur(),
                    autoComplete: "off",
                  }}
                  onKeyDown={(e) => {
                    const numberRegex = /^\d+$/;
                    const value = e.target.value;

                    switch (e.key) {
                      case "Backspace":
                      case "Delete":
                      case "ArrowLeft":
                      case "ArrowRight":
                      case "Tab":
                      case "Enter":
                        break;
                      case ".":
                        if (value.includes(".")) {
                          e.preventDefault();
                        }
                        break;
                      default:
                        if (!numberRegex.test(e.key)) {
                          return e.preventDefault();
                        }
                        if (
                          value.includes(".") &&
                          value.split(".")[1]?.length >= 2
                        ) {
                          return e.preventDefault();
                        }
                        break;
                    }
                  }}
                  sx={{
                    borderRadius: "6px",
                    "& .MuiOutlinedInput-input": {
                      color: text.primary,
                      fontSize: theme.typography.heading1,
                      fontWeight: theme.typography.heading1Bold,
                      lineHeight: "28px",
                      padding: "0px 0 0px 0px",
                      height: "unset",
                      minHeight: "unset",
                      borderBottom: `2px solid ${
                        error ? theme.palette.error.main : text.basic
                      } `,
                      maxWidth: "100px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },

                    "& .MuiOutlinedInput-input::placeholder": {
                      fontWeight: 600,
                      color: text.basic,
                    },

                    "input[type=number]::-webkit-inner-spin-button, \ninput[type=number]::-webkit-outer-spin-button":
                      {
                        WebkitAppearance: "none",
                        MozAppearance: "none",
                        appearance: "none",
                        margin: "0",
                      },

                    "& .Mui-disabled": {
                      opacity: 0.6,
                      cursor: "no-drop",
                    },
                    "& .MuiOutlinedInput-input.Mui-disabled": {
                      WebkitTextFillColor: text.primary,
                    },
                    "& .MuiOutlinedInput-notchedOutline ": {
                      border: "none",
                    },
                    ...inputSx,
                  }}
                />
                {guestCheckIn && (
                  <Typography
                    sx={{
                      color: text.secondary,
                      fontSize: theme.typography.heading2,
                      fontWeight: 400,
                    }}
                  >
                    {name === "weight"
                      ? guestUnitPreference === "Imperial"
                        ? "lb"
                        : "kg"
                      : guestUnitPreference === "Imperial"
                      ? "inches"
                      : "cm"}
                  </Typography>
                )}
                {!guestCheckIn && (
                  <Typography
                    sx={{
                      color: text.secondary,
                      fontSize: theme.typography.heading2,
                      fontWeight: 400,
                    }}
                  >
                    {name === "weight"
                      ? unitPreference === "Imperial"
                        ? "lb"
                        : "kg"
                      : unitPreference === "Imperial"
                      ? "inches"
                      : "cm"}
                  </Typography>
                )}
                <Collapse in={Boolean(error?.message)}>
                  <ErrorText
                    error={error}
                    errorSx={{
                      mt: "0px",
                      fontSize: { xs: "12px", sm: "14px" },
                      lineHeight: "normal",
                    }}
                  />
                </Collapse>
              </Stack>
            </Stack>
          )}
        />
      </Stack>
    )
  );
};
