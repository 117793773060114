function compareObjects(obj1, obj2) {
  // Get the keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // If the number of keys is not equal, objects are not equal
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Iterate through keys of obj1
  for (let key of keys1) {
    // If the value of obj1's key is an array, compare array elements recursively
    if (Array.isArray(obj1[key])) {
      if (!Array.isArray(obj2[key])) {
        // console.log(`Value for key ${key} is not an array in obj2`);
        return false;
      }
      const set1 = new Set(obj1[key]);
      const set2 = new Set(obj2[key]);
      // Check if set1 includes all elements of set2 and vice versa
      if (
        !Array.from(set1).every((value) => set2.has(value)) ||
        !Array.from(set2).every((value) => set1.has(value))
      ) {
        // console.log(`Values in array for key ${key} are different`);
        return false;
      }
    } else if (obj1[key] !== obj2[key]) {
      // If the value of obj1's key is not equal to the value of obj2's key, objects are not equal
      // console.log(`Value for key ${key} is different`);
      return false;
    }
  }

  // If all key-value pairs are equal, objects are equal
  return true;
}

module.exports = { compareObjects };
