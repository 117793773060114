import * as React from "react";
// import { LineChart as LineChartMUI } from "@mui/x-charts/LineChart";
import { useAuth } from "../../hooks/store/useAuth";
import { formatFirebaseTimestamp } from "../../functions/formatFirebaseTimestamp";
import { calculateBodyComposition } from "../../functions/bodyFatCalc";
import Skeleton from "../skeleton/Skeleton";
import useRealTimeData from "../../hooks/useRealTimeData";
import ReactApexCharts from "react-apexcharts";
import { Box, useTheme } from "@mui/material";
import { measurementUnits } from "../../constants/measurementUnit";
import { lbToKg } from "../../functions/unitConvertor";

export default function LineChart() {
  const { userId, sex, unitPreference } = useAuth();
  const { palette, typography } = useTheme();
  const { loading, data } = useRealTimeData(
    "/checkIns",
    "userId",
    "==",
    userId,
    "createdAt",
    "asc"
  );
  const [chartLabel, setChantLabel] = React.useState(false);
  const [weightData, setWeightData] = React.useState([]);
  const [bodyFatData, setBodyFatData] = React.useState([]);
  const minWeight = Math.min(...weightData) * 0.95; // 5% less than minimum weight
  const maxWeight = Math.max(...weightData) * 1.05; // 5% more than maximum weight
  const minBF = Math.min(...bodyFatData) - 3; // Minimum BF% on record - 3%
  const maxBF = Math.max(...bodyFatData) + 3; // Maximum BF% on record + 3%

  const fetchTableData = React.useCallback(
    (data) => {
      const dayFilterArray = [];
      const calculatedData = [];
      const weightArray = [];
      const fatMassArray = [];

      data.map((record) => {
        return calculatedData.push({
          id: record.id,
          weight: record.weight,
          date: record?.createdAt?.seconds,
          ...calculateBodyComposition(
            record.weight,
            record.waist,
            record.neck,
            record.height,
            sex,
            record?.hip,
            "Fat Loss"
          ),
        });
      });

      calculatedData.map((record) => {
        dayFilterArray.push(formatFirebaseTimestamp(record.date, "DD MMM"));
        weightArray.push(
          unitPreference === measurementUnits.IMPERIAL
            ? record.weight
            : Number(lbToKg(record.weight)).toFixed(1)
        );
        fatMassArray.push(Number(record.accurateBF).toFixed(1));
        return record;
      });

      setChantLabel(dayFilterArray);
      setWeightData(weightArray);
      setBodyFatData(fatMassArray);
    },
    [sex, unitPreference]
  );

  React.useEffect(() => {
    if (data) {
      fetchTableData(data);
    }
  }, [fetchTableData, data]);

  // ---------------------------apex chart-------------------------

  let series = [
    {
      color: palette.info.secondary,
      name: "Weight",
      data: weightData,
    },
    {
      color: palette.success.light,
      name: "BF%",
      data: bodyFatData,
    },
  ];
  let options = {
    chart: {
      width: "100%",
      height: "100%",
      fontFamily: typography.fontFamily,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    legend: {
      fontSize: "14px",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    markers: {
      size: 6,
      hover: {
        size: 8,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
    },
    xaxis: {
      categories: chartLabel,
    },
    yaxis: [
      {
        min: minWeight,
        max: maxWeight,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: palette.info.secondary,
        },
        labels: {
          style: {
            colors: palette.info.secondary,
          },
        },
        title: {
          text: "Weight",
          style: {
            color: palette.info.secondary,
          },
        },
      },
      {
        opposite: true,
        min: minBF,
        max: maxBF,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: palette.success.light,
        },
        labels: {
          style: {
            colors: palette.success.light,
          },
        },
        title: {
          text: "BF%",
          style: {
            color: palette.success.light,
          },
        },
      },
    ],
  };

  return (
    <>
      {loading && (
        <Skeleton
          sx={{
            mt: "14px",
            width: "100%",
            height: { xs: "240px", sm: "350px" },
          }}
        />
      )}

      {!loading && weightData && (
        <Box sx={{ height: { xs: "240px", sm: "350px" } }}>
          <ReactApexCharts
            options={options}
            series={series}
            height={"100%"}
            type="area"
          />
        </Box>
      )}
    </>
  );
}
