import { IconButton, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import * as amplitude from "@amplitude/analytics-browser";

const CustomPagination = ({
  currentMonth,
  setCurrentMonth,
  monthFilterArray,
}) => {
  const { text, typography } = useTheme();

  function setPreviousMonth() {
    amplitude.logEvent("Progress_Prev");
    return setCurrentMonth(
      monthFilterArray[monthFilterArray.indexOf(currentMonth) + 1]
    );
  }

  function setNextMonth() {
    amplitude.logEvent("Progress_Next");
    return setCurrentMonth(
      monthFilterArray[monthFilterArray.indexOf(currentMonth) - 1]
    );
  }

  return (
    <Stack
      sx={{
        mt: "12px",
        width: { sm: "fit-content" },
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "6px",
        gap: "28px",
      }}
    >
      <IconButton
        sx={{
          p: 0,
          fontFamily: typography.fontFamily,
          color: text.basic,
          fontSize: { xs: "18px", sm: "20px" },
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
        disabled={
          monthFilterArray.length - 1 === monthFilterArray.indexOf(currentMonth)
        }
        onClick={setPreviousMonth}
      >
        &lt;
      </IconButton>
      <Typography
        sx={{
          color: text.basic,
          fontSize: { xs: "16px", sm: "18px" },
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "22px",
        }}
      >
        {currentMonth}
      </Typography>
      <IconButton
        sx={{
          p: 0,
          "&:hover": {
            backgroundColor: "transparent",
          },
          color: text.basic,
          fontFamily: typography.fontFamily,
          fontSize: { xs: "18px", sm: "20px" },
        }}
        disabled={0 === monthFilterArray.indexOf(currentMonth)}
        onClick={setNextMonth}
      >
        &gt;
      </IconButton>
    </Stack>
  );
};

export default CustomPagination;
