import * as amplitude from "@amplitude/analytics-browser";
import { RouterProvider } from "react-router-dom";
import { routerConfig } from "./router";
import { useCallback, useEffect } from "react";
import { useGuest } from "./hooks/store/useGuest";
import { generateUniqueId } from "./functions/generateUniqueId";
import Toast from "./components/global/toast/Toast";
import useGetRealTimeData from "./hooks/useGetRealTimeData";
import { useAuth } from "./hooks/store/useAuth";
import useFindData from "./hooks/useFindData";
import { useCommon } from "./hooks/store/useCommon";
import { clarity } from "react-microsoft-clarity";
import { matchCountryCode } from "./utils/SmsSeviceChecker";
import useApiCall from "./hooks/useApiCall";

function App() {
  // code for amplitude initialization
  const { setFromTrigger } = useCommon();
  useEffect(() => {
    clarity.init(process.env.REACT_APP_CLARITY_id);
    amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY, {
      defaultTracking: true,
    });
    const Trigger = new URLSearchParams(window.location.search).get("Trigger");
    if (Trigger) {
      setFromTrigger(Trigger);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // code for guest user
  const { guestUserId, setGuestUserId, setCurrentCheckInId } = useGuest();
  const { isLoggedIn, userId, setUseDetails, logout } = useAuth();
  const { findAllData, findData } = useFindData();
  const { apiCall } = useApiCall();
  const { setSettings } = useCommon();
  let { response } = useGetRealTimeData("/users", userId);
  let { response: reminderResponse } = useGetRealTimeData("/reminder", userId);
  const getSettings = useCallback(async () => {
    let data = await findAllData("/settings");
    setSettings(data[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect for set referer
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const referrer = params.get("ref");
    setUseDetails({ referrer });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getSettings();
    if (!guestUserId && !isLoggedIn) {
      let generateGuestId = generateUniqueId();
      amplitude.setUserId(generateGuestId);
      setGuestUserId(generateGuestId);
    } else {
      setCurrentCheckInId();
    }
  }, [
    guestUserId,
    setGuestUserId,
    setCurrentCheckInId,
    getSettings,
    isLoggedIn,
  ]);

  useEffect(() => {
    async function fetchLatestUserInfo() {
      if (isLoggedIn && response !== null) {
        if (!response.inActive) {
          // set the body stage property to user object of amplitude
          amplitude.setUserId(response?.id);
          const identifyObj = new amplitude.Identify();
          identifyObj.set("Phone", response?.phone ? "Yes" : "No");
          let Notifications = "OFF";
          if (reminderResponse) {
            if (reminderResponse?.getEmails && !reminderResponse?.getMessages) {
              Notifications = "Email";
            }

            if (reminderResponse?.getMessages && !reminderResponse?.getEmails) {
              Notifications = "SMS";
            }

            if (reminderResponse?.getMessages && reminderResponse?.getEmails) {
              Notifications = "Email,SMS";
            }

            identifyObj.set(Notifications);
          }
          identifyObj.set("Notifications", Notifications);
          const settings = await findAllData("/settings");
          const Affordability = response?.phone
            ? matchCountryCode(
                response?.phone,
                settings[0]?.SmsServicesAvailability
              )
              ? "Yes"
              : "No"
            : "NoPhoneNumber";
          identifyObj.set("Affordability", Affordability);
          identifyObj.set("User ID", response?.id);
          identifyObj.set("Status", "Logged In");

          amplitude.identify(identifyObj);

          // get user height for further check Ins
          const lastCheckIn = await findData(
            "/checkIns",
            "userId",
            "==",
            response.id
          );
          const height = lastCheckIn[0]?.height
            ? lastCheckIn[0]?.height
            : undefined;

          // check user's close crm lead status
          const leadStatus = await apiCall("post", "/checkLeadStatus", {
            body: { email: response?.email },
          });

          setUseDetails({
            ...response,
            ...reminderResponse,
            height,
            Affordability,
            CloseLeadStatus: leadStatus?.status,
          });
        } else logout();
      } else {
        const identifyObj = new amplitude.Identify();
        identifyObj.set("Status", "Not Logged In");
        amplitude.identify(identifyObj);
      }
    }
    fetchLatestUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, isLoggedIn, reminderResponse]);
  return (
    <>
      <RouterProvider router={routerConfig} />
      <Toast />
    </>
  );
}

export default App;