import {
  Button,
  FormControlLabel,
  Radio,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";

const RadioButton = ({
  value,
  label,
  Icon,
  SelectedIcon,
  selected,
  handleSelect,
}) => {
  const theme = useTheme({
    defaultValue: {},
  });
  const handleButtonClick = () => {
    handleSelect(value);
  };

  return (
    <>
      <FormControlLabel
        value={value}
        sx={{ display: "contents" }}
        control={<Radio sx={{ visibility: "hidden", position: "absolute" }} />}
      />
      <Stack
        fullWidth
        component={Button}
        sx={{
          maxWidth: { xs: "90px", sm: "140px" },
          color: theme.text.secondary,
          transition: "border-color .4 ease-in-out",
          alignItems: "center",
          justifyContent: "center",
          gap: "5px",
        }}
        onClick={handleButtonClick}
      >
        {selected === value ? (
          <SelectedIcon
            sx={{
              width: { xs: "50px", sm: "70px" },
              height: { xs: "50px", sm: "70px" },
            }}
          />
        ) : (
          <Icon
            sx={{
              width: { xs: "50px", sm: "70px" },
              height: { xs: "50px", sm: "70px" },
            }}
          />
        )}
        <Typography
          sx={{
            textTransform: "capitalize",
            fontSize: theme.typography.heading2,
            fontWeight: theme.typography.heading2Bold,
            color:
              selected === value ? theme.text.primary : theme.text.secondary,
          }}
        >
          {label}
        </Typography>
      </Stack>
    </>
  );
};

export default RadioButton;
