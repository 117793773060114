function performanceAdviser(
  leanMass,
  fatMass,
  fatMassPercentage,
  checkInGap,
  bodyWeightLossMoreThanOnePercentage
) {
  let advice = { title: "", description: [], index: 0, status: "" };

  if ((fatMass > 0 && leanMass > 0) || (fatMass > 0 && leanMass === 0)) {
    advice.title = "You’re in calorie surplus";
    advice.description.push(
      `If you’ve not been tracking your food or you’ve been eye-balling portions for tracking, try tracking with a food scale for only the next 7 days to spot the top 1-2 things that are causing the surplus.`
    );
    advice.index = 1;
    advice.status = "Gaining";
  } else if (fatMass > 0 && leanMass < 0) {
    advice.title = "You’re in catabolic state";
    advice.description.push(
      "Double check the batteries in your scale and that your tape measure isn’t stretched out."
    );
    advice.description.push(
      "If that’s all good and this is the first time in a while that fat mass has gone up while lean mass went down, wait till next week before making changes. "
    );
    advice.description.push(
      "If this is the second consecutive week this has happened, your body is stressed. Up your calories to maintenance, ignore macros for now, cut back to low-intensity walks, and get high quality 8h sleep. "
    );
    advice.index = 2;
    advice.status = "Catabolic";
  } else if (
    (fatMass === 0 && leanMass > 0) ||
    (fatMass === 0 && leanMass === 0)
  ) {
    advice.title = "You’re maintaining";
    advice.description.push(
      "If you’ve not been tracking your food or you’ve been eye-balling portions for tracking, try tracking with a food scale for only the next 7 days to spot the 1-2 top foods that, if reduced, would put you in a calorie deficit and resume fat loss."
    );
    advice.index = 3;
    advice.status = "Maintaining";
  } else if (bodyWeightLossMoreThanOnePercentage && checkInGap <= 7) {
    advice.title = "Keep going (maybe)";
    advice.description.push(
      "You’re losing more fat than lean mass (amazing!) but you’ve lost >1% bodyweight over 7 days, which is faster than what’s sustainable long-term."
    );
    advice.description.push(
      "If you’re feeling hungry or low energy, experiment with upping your calories by 10% and seeing if your bodyweight loss settles into  a sustainable 0.5-1% loss per week."
    );
    advice.index = 4;
    advice.status = "ProgressingTooFast";
  } else if (
    (fatMass === 0 && leanMass < 0) ||
    (fatMass < 0 && leanMass < 0 && fatMass > leanMass)
  ) {
    advice.title = "You’re stretched thin";
    advice.description.push(
      "If this is the first week in a while that you’ve lost more lean mass than fat mass, it may be a one-off. Wait till next week before making changes."
    );
    advice.description.push(
      "If this is the second week, then experiment with upping your calories  and/or protein by 10%."
    );
    advice.index = 5;
    advice.status = "SkinnyFatRisk";
  } else if (fatMass < 0 && leanMass > 0) {
    advice.title = "Keep going!";
    advice.description.push(
      "You gained lean mass while losing fat - the ideal scenario! Keep doing what you’re doing. Remember, it’s about fat loss - not weight loss."
    );
    advice.index = 6;
    advice.status = "Progressing";
  } else if (fatMass < 0 && leanMass < 0 && fatMass > leanMass) {
    advice.title = "Keep going!";
    advice.description.push(
      "You’re losing more fat than lean mass - amazing! As long as fat loss is more than lean mass loss, you’re doing great!"
    );
    advice.index = 7;
    advice.status = "Progressing";
  } else if (fatMass < 0 && leanMass === 0) {
    advice.title = "Keep going!";
    advice.description.push(
      "You’re losing more fat without losing lean mass - this is amazing!"
    );
    advice.index = 8;
    advice.status = "Progressing";
  }

  return advice;
}

module.exports = { performanceAdviser };
