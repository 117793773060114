const dayjs = require("dayjs");
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

export default function getTimeInUtc(scheduledTime, timeZone) {
  try {
    // Get the current date in the specified time zone
    const today = dayjs.tz(dayjs(), timeZone);

    // Create a date time string for today with the provided scheduled time
    const scheduledDateTime = dayjs.tz(
      `${today.format("YYYY-MM-DD")} ${scheduledTime}`,
      timeZone
    );

    // Get the current time in the specified time zone
    const currentTimeInTimeZone = dayjs.tz(dayjs(), timeZone);

    // Check if the scheduledDateTime is in the past
    const isPast = scheduledDateTime.isBefore(currentTimeInTimeZone);
    return {
      utcTime: scheduledDateTime.utc().format(), // Convert to UTC and return the ISO 8601 formatted string
      isPast: isPast, // Return whether the scheduled time is in the past
    };
  } catch (error) {
    console.log("error: " + error.message);
    return null; // Return null in case of error
  }
}
