import { Collapse, Stack, TextField, alpha, useTheme } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import ErrorText from "./ErrorText";
import ElementLabel from "./ElementLabel";

const NewInputField = ({
  control,
  name,
  type,
  stackXs = {},
  label = false,
  description = false,
  errorMsg = true,
}) => {
  const { text } = useTheme();
  const theme = useTheme();
  return (
    <Stack sx={{ width: "100%", mt: theme.margin.margin1, ...stackXs }}>
      <ElementLabel label={label} description={description} />
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <TextField
              type={type}
              value={value}
              onChange={onChange}
              error={Boolean(error)}
              sx={{
                backgroundColor: "background.main",
                borderRadius: "0px",
                "& .MuiOutlinedInput-input": {
                  color: text.primary,
                  fontSize: theme.typography.heading1,
                  fontWeight: theme.typography.heading1Bold,
                  fontStyle: "normal",
                  lineHeight: "18px",
                  height: "unset",
                  minHeight: "unset",
                  borderBottom: "2px solid",
                  borderColor: alpha(text.basic, 0.4),
                  padding: { xs: "6px 2px", sm: "4px 2px" },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                  borderRadius: "0px",
                },

                "& .MuiOutlinedInput-input::placeholder": {
                  fontWeight: 400,
                },
                "& .MuiOutlinedInput-notchedOutline ": {
                  border: "none",
                },
                "& .css-104c99h-MuiButtonBase-root-MuiIconButton-root ": {
                  borderBottom: "2px solid",
                  borderColor: alpha(text.basic, 0.4),
                  borderRadius: 0,
                  padding: "8px 2px",
                },
              }}
            />
            <Collapse in={Boolean(error) && errorMsg}>
              <ErrorText error={error} />
            </Collapse>
          </>
        )}
      />
    </Stack>
  );
};

export default NewInputField;
