import dayjs from "dayjs";
import * as yup from "yup";

export const checkInDateSchema = yup.object().shape({
  checkInDate: yup
    .date("CheckIn date is required")
    .typeError("Please select a valid CheckIn date.")
    .required("CheckIn date is required")
    .test({
      name: "maxDateValidation",
      message:
        "Please ensure the CheckIn date entered is within the last year.",
      test: (value) => {
        const maxDate = dayjs();
        const minDate = dayjs().subtract(1, "year");
        return (
          dayjs(value).isValid() &&
          dayjs(value).isAfter(minDate) &&
          dayjs(value).isBefore(maxDate.add(1, "day"))
        );
      },
    }),
});
