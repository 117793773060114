import * as amplitude from "@amplitude/analytics-browser";
import React, { useCallback, useEffect, useState } from "react";
import RadioButton from "../global/form/RadioButton";
import RadioGroup from "../global/form/RadioGroup";
import { Controller, useForm } from "react-hook-form";
import PrimaryButton from "../global/button/PrimaryButton";
import { Stack } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFirstCheckIn } from "../../hooks/store/useFirstCheckIn";
import dayjs from "dayjs";
import { BasicDetailsSchema } from "../../validators/BasicDetailsSchema";
import FatLossIcon from "../../icons/radio-Icon/FatLossIcon";
import FatLossActiveIcon from "../../icons/radio-Icon/FatLossActiveIcon";
import MuscleGainActiveIcon from "../../icons/radio-Icon/MuscleGainActiveIcon";
import MuscleGainIcon from "../../icons/radio-Icon/MuscleGainIcon";
import MaleIcon from "../../icons/radio-Icon/MaleIcon";
import MaleActiveIcon from "../../icons/radio-Icon/MaleActiveIcon";
import FemaleIcon from "../../icons/radio-Icon/FemaleIcon";
import FemaleActiveIcon from "../../icons/radio-Icon/FemaleActiveIcon";
import NewDatePicker from "../global/form/NewDatePicker";
import MainContainer from "../global/container/MainContainer";
import Breadcrumb from "./Breadcrumb";
import { useNavigate } from "react-router-dom";
import { useCommon } from "../../hooks/store/useCommon";

const options = [
  {
    label: "Fat Loss",
    value: "Fat Loss",
    Icon: FatLossIcon,
    SelectedIcon: FatLossActiveIcon,
  },
  {
    label: "Muscle Gain",
    value: "Muscle Gain",
    Icon: MuscleGainIcon,
    SelectedIcon: MuscleGainActiveIcon,
  },
];

const genderOptions = [
  {
    label: "Female",
    value: "Female",
    Icon: FemaleIcon,
    SelectedIcon: FemaleActiveIcon,
  },
  {
    label: "Male",
    value: "Male",
    Icon: MaleIcon,
    SelectedIcon: MaleActiveIcon,
  },
];

const GoalForm = () => {
  useEffect(() => {
    const Trigger = new URLSearchParams(window.location.search).get("Trigger");
    amplitude.logEvent("Goal_Visited", {
      Trigger: Trigger ? Trigger : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();
  const {
    goal: storedGoal,
    setBasicDetails,
    gender: storedGender,
    dateOfBirth,
  } = useFirstCheckIn();

  const { handleSubmit, control, setValue, formState } = useForm({
    defaultValues: {
      goal: storedGoal,
      gender: storedGender,
      dateOfBirth: dateOfBirth ? dayjs(dateOfBirth) : null,
    },
    resolver: yupResolver(BasicDetailsSchema),
    mode: "onChange",
  });

  const [goal, setGoal] = useState(storedGoal === "" ? false : storedGoal);
  const [gender, setGender] = useState(
    storedGender === "" ? false : storedGender
  );
  const { fromTrigger } = useCommon();

  function selectGoal(value) {
    setGoal(value);
    setValue("goal", value);
  }

  function selectGender(value) {
    setGender(value);
    setValue("gender", value);
  }

  const generateRadioOptions = (options, selected, handleSelect) => {
    return options.map((singleOption) => (
      <RadioButton
        key={singleOption.value}
        value={singleOption.value}
        label={singleOption.label}
        Icon={singleOption.Icon}
        selected={selected}
        SelectedIcon={singleOption.SelectedIcon}
        handleSelect={handleSelect}
      />
    ));
  };

  const onSubmit = useCallback(
    (data) => {
      setBasicDetails(data);
      amplitude.logEvent("Goal_Submitted", {
        Goal: String(data.goal).replace(" ", ""),
        Sex: data.gender,
        Trigger: fromTrigger ? fromTrigger : undefined,
      });
      return navigate("/measurement");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fromTrigger]
  );
  return (
    <MainContainer>
      <Breadcrumb />
      <Stack
        component={"form"}
        onSubmit={handleSubmit(onSubmit)}
        sx={{ alignItems: "flex-end", mb: "24px" }}
      >
        <Controller
          name={"goal"}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <RadioGroup
              label="Pick your Sex"
              title={"What’s your #1 goal?"}
              name={"goal"}
              value={value}
              onChange={onChange}
              error={error}
            >
              {generateRadioOptions(options, goal, selectGoal)}
            </RadioGroup>
          )}
        />

        <Controller
          name={"gender"}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <RadioGroup
              label="Pick your Sex"
              title={"Your sex"}
              name={"gender"}
              value={value}
              onChange={onChange}
              error={error}
            >
              {generateRadioOptions(genderOptions, gender, selectGender)}
            </RadioGroup>
          )}
        />

        <NewDatePicker
          value={dateOfBirth} // Pass the correct value
          onChange={(newValue) => {
            setValue("dateOfBirth", newValue); // Update the form value
          }}
          error={formState?.errors?.dateOfBirth}
          minYear={18}
          maxYear={86}
          label={"Your birthday"}
        />

        <PrimaryButton
          type="submit"
          sx={{
            mt: "48px",
            width: { xs: "220px", md: "unset" },
            alignSelf: "center",
          }}
        >
          Next
        </PrimaryButton>
      </Stack>
    </MainContainer>
  );
};

export default GoalForm;
