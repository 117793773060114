import { Collapse, Stack, alpha, useTheme } from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import React from "react";
import { Controller } from "react-hook-form";
import ErrorText from "./ErrorText";
import ElementLabel from "./ElementLabel";

const MobileNumberInput = ({
  control,
  name,
  stackXs = {},
  label = false,
  description = false,
  sx = {},
}) => {
  const { text } = useTheme();
  const theme = useTheme();
  return (
    <Stack sx={{ width: "100%", ...stackXs }}>
      <ElementLabel label={label} description={description} />
      <Controller
        name={name}
        control={control}
        // rules={{
        //   validate: (value) =>
        //     matchIsValidTel(value, { onlyCountries: ["FR"] }),
        // }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <MuiTelInput
              defaultCountry={"US"}
              value={value}
              onChange={onChange}
              error={Boolean(error)}
              placeholder="00 000 0000"
              sx={{
                mt: "12px",
                ml: "-14px",
                maxWidth: "70%",
                backgroundColor: "background.main",
                borderRadius: "0px",
                "& .MuiOutlinedInput-input": {
                  color: text.primary,
                  fontSize: theme.typography.heading1,
                  fontWeight: theme.typography.heading1Bold,
                  fontStyle: "normal",
                  lineHeight: "18px",
                  height: "unset",
                  minHeight: "unset",
                  borderBottom: "2px solid",
                  borderColor: alpha(text.basic, 0.4),
                  padding: { xs: "6px 2px", sm: "4px 2px" },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                  borderRadius: "0px",
                },

                "& .MuiOutlinedInput-input::placeholder": {
                  fontWeight: 400,
                },
                "& .MuiOutlinedInput-notchedOutline ": {
                  border: "none",
                },
                "& .MuiIconButton-root ": {
                  maxHeight: "33px",
                  borderBottom: "2px solid",
                  borderColor: alpha(text.basic, 0.4),
                  borderRadius: 0,
                  padding: "2px 4px",
                },
                ...sx,
              }}
            />
            <Collapse in={Boolean(error)}>
              <ErrorText error={error} />
            </Collapse>
          </>
        )}
      />
    </Stack>
  );
};

export default MobileNumberInput;
