import { Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { bodyStatusChecker } from "../../functions/bodyStatusChecker";
import { useAuth } from "../../hooks/store/useAuth";
import PendingIcon from "../../icons/PendingIcon";

function formatDifference(differenceArg) {
  const difference = Number(differenceArg);
  if (Number.isInteger(difference)) {
    return difference > 0 ? `+${difference}` : `${difference}`;
  } else {
    const fixedDifference = Number(difference.toFixed(1));
    if (fixedDifference === 0) {
      return "0";
    } else {
      return fixedDifference > 0 ? `+${fixedDifference}` : `${fixedDifference}`;
    }
  }
}

// round figure value
function roundFigure(value) {
  const difference = Number(value);
  const roundedFigure = Number(difference.toFixed(1));
  return roundedFigure;
}

const TableRow = ({
  rowName,
  difference,
  actualValue,
  valueIn = " lb",
  hideComparisons = false,
  adviseIndex = 0,
}) => {
  const { text, typography, palette } = useTheme();
  const { sex } = useAuth();
  return (
    <Stack
      sx={{
        display: "grid",
        gridTemplateRows: "1ft",
        gridTemplateColumns: "1fr 1fr 1fr",
        padding: "12px 16px",
        borderRadius: "12px",
        background:
          adviseIndex === 7 && !hideComparisons
            ? palette.success.secondary
            : !hideComparisons && roundFigure(difference) < 0
            ? rowName === "Lean Mass"
              ? palette.error.secondary
              : palette.success.secondary
            : hideComparisons || roundFigure(difference) === 0
            ? palette.background.grey
            : rowName === "Lean Mass"
            ? palette.success.secondary
            : palette.error.secondary,
      }}
    >
      {!hideComparisons ? (
        <Typography
          sx={{
            fontFamily: typography.fontFamily2,
            fontSize: { xs: "19.5px", sm: "22px" },
            fontWeight: 600,
            display: "inline-flex",
            textAlign: "left",
            alignItems: "center",
            color:
              adviseIndex === 7
                ? palette.success.dark
                : roundFigure(difference) < 0
                ? rowName === "Lean Mass"
                  ? palette.error.text
                  : palette.success.dark
                : roundFigure(difference) === 0
                ? text.basic
                : rowName === "Lean Mass"
                ? palette.success.dark
                : palette.error.text,
          }}
        >
          {formatDifference(difference)}
          {valueIn}
        </Typography>
      ) : (
        <PendingIcon />
      )}

      <Typography
        sx={{
          color: "table.label",
          fontSize: { xs: "12.5px", sm: "16px" },
          fontStyle: "normal",
          fontWeight: 200,
          lineHeight: "22px",
          textAlign: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        {rowName}
      </Typography>

      <Stack
        sx={{
          maxHeight: { xs: "39.25px", sm: "43px" },
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            fontFamily: typography.fontFamily2,
            fontSize: { xs: "19.5px", sm: "22px" },
            fontWeight: 600,
            color: text.basic,
            textAlign: "right",
            lineHeight: rowName === "Body Fat" ? "20px" : "unset",
          }}
        >
          {Number.isInteger(actualValue)
            ? actualValue
            : Number(actualValue).toFixed(1)}
          {valueIn}
        </Typography>

        {rowName === "Body Fat" &&
          bodyStatusChecker(sex, "Fat Loss", actualValue)?.status ===
            "Overfat" && (
            <Typography
              sx={{
                mt: "2px",
                fontFamily: typography.fontFamily2,
                color: "error.text",
                fontSize: { xs: "10px", sm: "12px" },
                lineHeight: "10px",
                textAlign: "right",
              }}
            >
              Unhealthy
            </Typography>
          )}
      </Stack>
    </Stack>
  );
};

export default TableRow;
