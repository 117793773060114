import React, { useEffect } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import MainContainer from "../global/container/MainContainer";
import Breadcrumb from "./Breadcrumb";
import { Box, Typography, useTheme } from "@mui/material";
import snapShotPng from "../../assets/other-images/teaser-catabolic.png";
import snapShotPng2 from "../../assets/other-images/teaser-maybe.png";
import MailForm from "../global/form/MailForm";
import { useFirstCheckIn } from "../../hooks/store/useFirstCheckIn";
import { useNavigate } from "react-router-dom";
import { useCommon } from "../../hooks/store/useCommon";

const BasicParagraph = ({ sx = {}, children }) => {
  const theme = useTheme();
  return (
    <Typography
      sx={{
        mt: "18px",
        fontSize: { xs: "16px", sm: "18px" },
        fontWeight: 400,
        color: theme.text.basic,
        lineHeight: { xs: "20px", sm: "24px" },
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

const FinalStep = () => {
  const { fromTrigger } = useCommon();
  useEffect(() => {
    amplitude.logEvent("FinalStep_Visited", {
      Trigger: fromTrigger ? fromTrigger : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const theme = useTheme();
  const { height } = useFirstCheckIn();
  const navigate = useNavigate();

  useEffect(() => {
    if (height === "") navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height]);
  return (
    <MainContainer>
      <Breadcrumb />

      <BasicParagraph
        sx={{
          mt: "24px",
          fontFamily: theme.typography.fontFamily2,
          fontSize: { xs: "22px", sm: "26px" },
          fontWeight: 600,
          color: theme.text.primary,
        }}
      >
        Now for the crucial part...
      </BasicParagraph>

      <BasicParagraph>
        Weight loss isn’t just about shedding pounds; we're firm believers that
        it's about making better choices and using science to lead a healthier
        life. And a key aspect of this is focusing on losing body fat while
        maintaining your lean mass.
      </BasicParagraph>

      <BasicParagraph>
        But here's the problem... there isn't a single fat loss tracker out
        there that helps you monitor your body composition changes while also
        providing guidance based on your unique progress…
      </BasicParagraph>

      <BasicParagraph
        sx={{
          mt: "24px",
          fontFamily: theme.typography.fontFamily2,
          fontSize: { xs: "18px", sm: "22px" },
          fontWeight: 600,
          color: theme.text.basic,
        }}
      >
        Until now!
      </BasicParagraph>

      <Box
        component={"img"}
        sx={{ mt: "16px", ml: "-24px", width: "calc(100% + 48px)" }}
        src={snapShotPng}
      />

      <BasicParagraph>
        We have released{" "}
        <Typography
          component={"span"}
          sx={{
            mt: "18px",
            fontSize: { xs: "14px", sm: "16px" },
            fontWeight: 700,
            color: theme.text.basic,
            lineHeight: { xs: "20px", sm: "24px" },
          }}
        >
          the world's first Body Fat Tracker
        </Typography>{" "}
        that not only helps you measure your fat loss but also offers direction
        to keep you on the right track.
      </BasicParagraph>

      <BasicParagraph>
        It provides you with insights and suggestions based on your body
        composition data, empowering you to make informed decisions about your
        diet and lifestyle.
      </BasicParagraph>

      <BasicParagraph>
        No more guesswork, no more frustration. Just a clear path towards a
        healthier, happier you!
      </BasicParagraph>

      <Box
        component={"img"}
        sx={{ mt: "16px", ml: "-24px", width: "calc(100% + 48px)" }}
        src={snapShotPng2}
      />

      <BasicParagraph>
        And here's the best part – we're offering early subscribers{" "}
        <Typography
          component={"span"}
          sx={{
            mt: "18px",
            fontSize: { xs: "14px", sm: "16px" },
            fontWeight: 700,
            color: theme.text.basic,
            lineHeight: { xs: "20px", sm: "24px" },
          }}
        >
          Free Access
        </Typography>{" "}
        to this ground-breaking tool. All you need to do is{" "}
        <Typography
          component={"span"}
          sx={{
            mt: "18px",
            fontSize: { xs: "14px", sm: "16px" },
            fontWeight: 700,
            color: theme.text.basic,
            lineHeight: { xs: "20px", sm: "24px" },
          }}
        >
          secure your spot by signing up today!
        </Typography>
      </BasicParagraph>

      <BasicParagraph>
        Sign up now and be among the first to experience the transformative
        power of our innovative Body Fat Tracker.
      </BasicParagraph>

      <BasicParagraph>
        Your journey to a healthier, more vibrant life starts here!
      </BasicParagraph>

      <MailForm />
    </MainContainer>
  );
};

export default FinalStep;
