import * as amplitude from "@amplitude/analytics-browser";
import { Button, Typography, useTheme } from "@mui/material";
import React, { useCallback } from "react";
import PrimaryButton from "../../global/button/PrimaryButton";
import ErrorText from "../../global/form/ErrorText";
import { MuiOtpInput } from "mui-one-time-password-input";
import useApiCall from "../../../hooks/useApiCall";
import { useAuth } from "../../../hooks/store/useAuth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import NormalCheckBox from "../../global/form/NormalCheckBox";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { matchCountryCode } from "../../../utils/SmsSeviceChecker";
import { useCommon } from "../../../hooks/store/useCommon";

export const verifySchema = yup.object().shape({
  terms: yup
    .bool()
    .oneOf([true], "You must agree to the terms and conditions")
    .required(),
});

const PhoneUpdateDialog = ({ formData, openContainer, handleClose }) => {
  const { text } = useTheme();
  const theme = useTheme();
  const { apiCall, error, loading } = useApiCall();
  const { apiCall: apiCall2 } = useApiCall();
  const { userId } = useAuth();
  const { settings } = useCommon();
  const navigate = useNavigate();
  const [otp, setOtp] = React.useState("");
  const [errorMsg, setErrorMsg] = React.useState(false);
  const { formState, control } = useForm({
    defaultValues: {},
    resolver: yupResolver(verifySchema),
    mode: "onChange",
  });

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  // code for send/resend otp
  let sendOtp = React.useCallback(async function (resend = false) {
    try {
      if (formData.number) {
        let response = await apiCall2("post", "/sendOtpToNewNumber", {
          body: { phone: formData.number },
        });
        return resend && toast(response.message);
      }
    } catch (error) {
      return toast(error.response.data.message, { type: "error" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // code fo verify otp
  const verifyOtp = useCallback(
    async function (otp) {
      try {
        if (otp.length !== 4) {
          return setErrorMsg({
            error: true,
            message: "Please provide a valid otp",
          });
        }

        setErrorMsg(false);

        if (Object.keys(formState.errors).length !== 0) return false;

        let response = await apiCall("post", "/verifyNumberAndUpdate", {
          body: { phone: formData.number, otp, userId },
        });
        toast(response.message);
        handleClose();
        const identifyObj = new amplitude.Identify();
        const Affordability = formData?.number
          ? matchCountryCode(
              formData?.number,
              settings?.SmsServicesAvailability
            )
            ? "Yes"
            : "No"
          : "NoPhoneNumber";
        identifyObj.set("Affordability", Affordability);
        amplitude.identify(identifyObj);
        amplitude.logEvent("Profile_SMSChanged");
        return navigate("/myprogress");
      } catch (error) {
        setErrorMsg({
          error: true,
          message: "Please provide a valid otp",
        });
        return toast(error.response.data.message, { type: "error" });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData.number, userId, handleClose, navigate, formState.errors]
  );

  React.useEffect(() => {
    openContainer === "phone update dialog" && sendOtp();
  }, [sendOtp, openContainer]);

  // code for verify otp
  const handleComplete = async (finalValue) => {
    try {
      verifyOtp(finalValue);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Typography
        sx={{
          mt: "24px",
          fontFamily: theme.typography.fontFamily2,
          fontSize: theme.typography.headingBasic,
          color: theme.text.basic,
          fontWeight: theme.typography.headingBasicBold,
        }}
      >
        Enter OTP
      </Typography>
      <Typography
        sx={{
          mt: "24px",
          color: text.basic,
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: { xs: "14px", sm: "16px" },
          marginTop: theme.margin.margin3,
          lineHeight: "20px",
          textAlign: "center",
        }}
      >
        We have sent a verification code to your mobile number:{" "}
        <Typography
          component={"span"}
          sx={{
            color: text.primary,
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: { xs: "14px", sm: "16px" },
            marginTop: theme.margin.margin3,
            lineHeight: "20px",
            textAlign: "center",
          }}
        >
          {formData.number}
        </Typography>
        . Please check your phone for the OTP.
      </Typography>

      <MuiOtpInput
        TextFieldsProps={{ type: "number" }}
        value={otp}
        length={4}
        onChange={handleChange}
        autoFocus
        onComplete={handleComplete}
        sx={{
          mx: "auto",
          mt: "24px",
          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
            {
              display: "none",
            },
          "& input[type=number]": {
            MozAppearance: "textfield",
          },

          "& .MuiOutlinedInput-input": {
            padding: { xs: "12px", md: "16px 14px" },
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: "16px",
            backgroundColor: "#ffffff",
            color: theme.text.primary,
            fontSize: theme.typography.heading1,
            fontWeight: theme.typography.heading2Bold,
          },
          "&.MuiOtpInput-Box": {
            maxWidth: "230px",
            flexWrap: "nowrap",
            justifyContent: "space-between",
            gap: { xs: "12px", sm: "10px", md: "15px" },
          },
        }}
      />

      <ErrorText error={error || errorMsg} />

      <NormalCheckBox
        control={control}
        name={"terms"}
        label={
          <Typography>
            I agree to receive body fat check-in reminders at my chosen times
            from Coach Viva. I can opt out at any time by changing my profile
            settings. Message & data rates may apply.{" "}
            <Typography
              sx={{ color: theme.text.primary, fontWeight: 500 }}
              component={"a"}
              href={"https://www.coachviva.com/privacy"}
              target="_blank"
            >
              Privacy Policy
            </Typography>{" "}
            &{" "}
            <Typography
              component={"a"}
              sx={{ color: theme.text.primary, fontWeight: 500 }}
              href={"https://www.coachviva.com/tos"}
              target="_blank"
            >
              Terms
            </Typography>
            .
          </Typography>
        }
        defaultChecked
      />

      <PrimaryButton
        isLoading={loading}
        onClick={() => verifyOtp(otp)}
        sx={{
          mt: "30px",
          p: "16px !important",
          width: "100%",
          fontSize: "18px !important",
        }}
      >
        Verify
      </PrimaryButton>

      <Typography
        sx={{
          mt: "18px",
          fontSize: { xs: "14px", sm: "16px" },
        }}
      >
        Didn’t receive an SMS?
      </Typography>
      <Button
        onClick={() => sendOtp(true)}
        variant="text"
        sx={{
          fontSize: { xs: "14px", sm: "16px" },
          textTransform: "unset",
          color: "#30a3fa",
        }}
      >
        Click here to send new code
      </Button>
    </>
  );
};

export default PhoneUpdateDialog;
