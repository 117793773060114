import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useCommon } from "../../../hooks/store/useCommon";
import { ScrollToTop } from "../ScrollToTop";
import Navbar from "../../navbar/Navbar";
import EditCheckInDialog from "../../my-progress/EditCheckInDialog";
import SuggestionDialog from "../../my-progress/SuggestionDialog";

const MainContainer = ({
  children,
  sx,
  showNavbar = true,
  containerSx = {},
  ...rest
}) => {
  // code for show/hide navigation
  const {
    navbarVisibility,
    setNavbarVisibility,
    openUpdateCheckInDialog,
    setOpenUpdateCheckInDialog,
    suggestionDialog,
    setOpenSuggestionDialog,
  } = useCommon();
  useEffect(() => {
    if (navbarVisibility !== showNavbar) {
      setNavbarVisibility(showNavbar);
    }
  }, [navbarVisibility, showNavbar, setNavbarVisibility]);

  return (
    <Box
      sx={{
        overflowX: "hidden",
        width: "100%",
        maxWidth: "100%",
        height: "100vh",
      }}
    >
      <ScrollToTop />
      <Navbar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "background.secondary",
          width: "100%",
          ...sx,
        }}
        {...rest}
      >
        <Box
          sx={{
            backgroundColor: "background.main",
            width: "100%",
            maxWidth: "550px",
            mx: "auto",
            px: "24px",
            pb: "48px",
            minHeight: {
              xs: showNavbar ? "calc(100vh - 90px)" : "100vh",
            },
            ...containerSx,
          }}
        >
          {children}
          {/* container level dialog components */}
          {openUpdateCheckInDialog && (
            <EditCheckInDialog
              openDialog={openUpdateCheckInDialog}
              setOpenDialog={setOpenUpdateCheckInDialog}
            />
          )}

          {suggestionDialog && (
            <SuggestionDialog
              openDialog={suggestionDialog}
              setOpenDialog={setOpenSuggestionDialog}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default MainContainer;
