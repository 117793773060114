import { useState } from "react";
import {
  query,
  where,
  getDocs,
  deleteDoc,
  collection,
} from "firebase/firestore";
import { db } from "../services/firebase";

const useDeleteAllData = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const deleteDataByCondition = async (
    collectionPath,
    fieldName,
    operator,
    value
  ) => {
    try {
      setLoading(true);
      setError(false);
      setErrorMessage("");
      setSuccess(false);

      // Query documents to delete based on the condition
      const q = query(
        collection(db, collectionPath),
        where(fieldName, operator, value)
      );

      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        // No matching documents found
        setSuccess(true);
        return;
      }

      // Delete each matching document
      const deletePromises = querySnapshot.docs.map(async (doc) => {
        await deleteDoc(doc.ref); // Use the document reference directly
      });

      await Promise.all(deletePromises);

      setSuccess(true);
    } catch (error) {
      console.error(error);
      setError(true);
      setErrorMessage(error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, errorMessage, success, deleteDataByCondition };
};

export default useDeleteAllData;
