import * as amplitude from "@amplitude/analytics-browser";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import useApiCall from "../hooks/useApiCall";
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import { toast } from "react-toastify";
import useFindData from "../hooks/useFindData";
import { useAuth } from "../hooks/store/useAuth";
import useUpdateData from "../hooks/useUpdateData";
import { measurementUnits } from "../constants/measurementUnit";
import BG from "../assets/other-images/background.png";
import PrimaryButton from "../components/global/button/PrimaryButton";
import dayjs from "dayjs";
import getTimeInUtc from "../utils/convertTimeToUtc";
import { matchCountryCode } from "../utils/SmsSeviceChecker";
const VerifyUser = () => {
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const { text } = useTheme();
  const { apiCall } = useApiCall();
  const { findData, findDataById, findAllData } = useFindData();
  const { updateData } = useUpdateData();
  const [verified, setVerified] = useState({ request: "", verified: false });
  const { isLoggedIn, setUseDetails } = useAuth();
  const token = query.get("token");
  const redirectTo = query.get("redirectTo");
  const from = query.get("from");

  const verifyToken = useCallback(async () => {
    try {
      let result = await apiCall("post", "/verifyToken", {
        body: { token: token },
      });

      if (result?.data?.tapeReminder) {
        // verify tape reminder request email
        let userDetails = await findData(
          "/tapeReminder",
          "recordId",
          "==",
          result.data.record.recordId
        );
        userDetails = userDetails[0];

        // set email verification true if not verified
        if (!userDetails.emailVerified) {
          await updateData(
            "/tapeReminder",
            { emailVerified: true },
            userDetails.id
          );
        }
        amplitude.logEvent("ThankYou_ConfirmedEmail", {
          Trigger: "TapeReminder",
        });
        return setVerified({ request: "tape-reminder", verified: true });
      } else {
        // code for email verification and login
        let userDetails = await findData("/users", "email", "==", result.email);
        if (!userDetails) {
          toast("The account you're looking for does not exist.", {
            type: "warning",
          });
          return navigate("/");
        }
        userDetails = userDetails[0];

        // get user height for further check Ins
        const lastCheckIn = await findData(
          "/checkIns",
          "userId",
          "==",
          userDetails.id
        );
        const userHeight = lastCheckIn[0]?.height;

        // set email verification true if not verified
        if (!userDetails.emailVerified) {
          await updateData("/users", { emailVerified: true }, userDetails.id);

          // get reminder preferences for same day check in reminder scheduling & check affordability
          const settings = await findAllData("/settings");
          let affordability = false;
          if(userDetails?.phone){
            affordability = matchCountryCode(
              userDetails?.phone,
              settings[0]?.SmsServicesAvailability
            );
          }


          if (affordability) {
            const reminderPreferences = await findDataById(
              "/reminder",
              userDetails.id
            );

            const dayOfWeek = dayjs().format("dddd");
            const userSelectedTime =
              reminderPreferences?.reminderPreferences?.find(
                (pref) => pref[dayOfWeek]
              );

            if (userSelectedTime) {
              const timeInUtc = getTimeInUtc(
                userSelectedTime[dayOfWeek],
                reminderPreferences.timeZone
              );

              if (!timeInUtc?.isPast) {
                apiCall("post", "/scheduleCheckInReminder", {
                  body: {
                    userId: userDetails.id,
                    email: userDetails?.email,
                    time: timeInUtc?.utcTime,
                    timeZone: reminderPreferences.timeZone,
                    phone: userDetails?.phone,
                  },
                });
              }
            }
          }

          amplitude.setUserId(userDetails.id);
          amplitude.logEvent("Confirm_Visited");
        } else {
          amplitude.setUserId(userDetails.id);
          amplitude.logEvent("Login", {
            from: from,
          });
        }

        setUseDetails({
          isLoggedIn: true,
          userId: userDetails.id,
          name: userDetails.name,
          email: userDetails.email,
          emailVerified: true,
          phone: userDetails.phone,
          phoneVerified: userDetails.phoneVerified,
          sex: userDetails.sex,
          dateOfBirth: userDetails.dateOfBirth,
          unitPreference: userDetails?.unitPreference
            ? userDetails.unitPreference
            : measurementUnits.IMPERIAL,
          height: userHeight,
        });

        return userDetails.emailVerified
          ? redirectTo
            ? navigate(redirectTo)
            : navigate("/myprogress")
          : setVerified({ request: "email-verification", verified: true });
      }
    } catch (error) {
      console.error(error);
      if (error.response.data.message === "token is expired") {
        toast("Link expired. Enter your email for new login link", {
          type: "warning",
        });
        return navigate("/login");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, redirectTo, from]);

  useEffect(() => {
    if (token && !isLoggedIn) {
      verifyToken();
    } else {
      return redirectTo ? navigate(redirectTo) : navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, verifyToken, redirectTo]);
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        backgroundImage: `url(${BG})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {!verified?.verified ? (
        <CircularProgress sx={{ color: text.main }} />
      ) : (
        <>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "24px",
              fontWeight: "600  ",
              color: text.main,
            }}
          >
            Your email is confirmed.
          </Typography>
          {verified?.request === "email-verification" && (
            <PrimaryButton
              component={Link}
              to="/myprogress"
              sx={{ maxWidth: { xs: "250px", sm: "250px" } }}
            >
              See my progress page
            </PrimaryButton>
          )}
        </>
      )}
    </Box>
  );
};

export default VerifyUser;
