import * as React from "react";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import PrimaryButton from "../../global/button/PrimaryButton";
import ElementLabel from "../../global/form/ElementLabel";
import { Link } from "react-router-dom";

export default function SetPhoneNumberDialog({ openDialog, setOpenDialog }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        sx={{
          boxShadow: "none",
          "& .MuiBackdrop-root": {
            background: "rgba(255,255,255,0.7)",
          },
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: { md: "460px" },
              paddingInline: "18px",
              paddingBlock: "28px",
              backgroundColor: "#faf9f9",
              borderRadius: "20px",
            },
          },
        }}
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <ElementLabel
          label={"Set up phone number"}
          description={
            "Add Your Phone Number for Reminder Alerts: Ensure you never miss a reminder by adding your phone number now."
          }
          labelSx={{
            fontFamily: theme.typography.fontFamily2,
            fontSize: { xs: "18px", sm: "22px" },
            lineHeight: { xs: "22px", sm: "24px" },
            textAlign: "left",
          }}
          descriptionSx={{
            mt: { xs: "8px", sm: "12px" },
            fontSize: { xs: "14px", sm: "16px" },
            lineHeight: { xs: "16px", sm: "20px" },
            textAlign: "left",
          }}
        />
        <PrimaryButton
          component={Link}
          to={"/profile"}
          sx={{
            mt: "24px",
            maxWidth: { md: "312px !important" },
            width: "100%",
          }}
        >
          Set Phone Number
        </PrimaryButton>
      </Dialog>
    </React.Fragment>
  );
}
