import React from "react";
import { useAuth } from "../hooks/store/useAuth";
import { BeforeLoggedInMeasurementsForm as BeforeLoggedIn } from "../components/home/BeforeLoggedInMeasurementsForm";
import { AfterLoggedInMeasurementsForm as AfterLoggedIn } from "../components/check-in/AfterLoggedInMeasurementsForm";

const Measurement = () => {
  const { isLoggedIn } = useAuth();
  return isLoggedIn ? <AfterLoggedIn /> : <BeforeLoggedIn />;
};

export default Measurement;
