import * as amplitude from "@amplitude/analytics-browser";
import React, { useCallback, useEffect, useState } from "react";
import { Box, Collapse, Stack, Typography, useTheme } from "@mui/material";
import { SelectInput } from "../../global/form/SelectInput";
import PrimaryButton from "../../global/button/PrimaryButton";
import { useForm } from "react-hook-form";
import {
  dayOfWeek,
  repetitionInterval,
  timeZones,
} from "../../../inputConfigs/reminderSource";
import { ReminderSettingsSchema } from "../../../validators/ReminderSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "../../../hooks/store/useAuth";
import useFindData from "../../../hooks/useFindData";
import { CheckBox } from "../../global/form/CheckBox";
import dayjs from "dayjs";
import NewTimePicker from "../../global/form/NewTimePicker";
import ErrorText from "../../global/form/ErrorText";
import ElementLabel from "../../global/form/ElementLabel";
import { NewRadioButton } from "../../global/form/NewRadioButton";
import { compareObjects } from "../../../utils/compareObjects";
import BtnToast from "../../global/toast/BtnToast";
import Skeleton from "../../skeleton/Skeleton";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import SetPhoneNumberDialog from "../dialog/SetPhoneNumberDialog";
import useUpdateData from "../../../hooks/useUpdateData";
import getTimeInUtc from "../../../utils/convertTimeToUtc";
import useApiCall from "../../../hooks/useApiCall";
dayjs.extend(tz);
dayjs.extend(utc);

const Reminders = () => {
  useEffect(() => {
    amplitude.logEvent("Notifications_Visited");
  }, []);

  const { userId, phone, email, Affordability } = useAuth();
  const { loading, updateData } = useUpdateData();
  const { findDataById } = useFindData();
  const { apiCall } = useApiCall();
  const theme = useTheme();
  const [showDayOfWeekInput, setShowDayOfWeekInput] = useState(true);
  const [fetchRecord, setFetchRecord] = useState(false);
  const [reminderSetting, setReminderSetting] = useState([]);
  const [defaultValue, setDefaultValue] = useState({});
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [toastVisibility, setToastVisibility] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const { handleSubmit, control, setValue, reset, formState, watch } = useForm({
    resolver: yupResolver(ReminderSettingsSchema),
    mode: "onChange",
  });

  const watchedValues = watch();
  useEffect(() => {
    let result = compareObjects(watchedValues, defaultValue);
    if (
      watchedValues.time !== null &&
      (watchedValues.repetitionInterval === "Weekly" &&
      watchedValues.dayOfWeek === ""
        ? false
        : true)
    )
      setDisableSubmit(result);
    else setDisableSubmit(true);

    return () => {
      result = undefined;
      setDisableSubmit(false);
    };
  }, [watchedValues, defaultValue]);

  // fetch existing details
  async function getExistingInfo() {
    let data = await findDataById("/reminder", userId);
    setDefaultValue((prev) => ({
      ...prev,
      reminderSetting: [
        data?.getEmails ? "getEmails" : "",
        data?.getMessages && phone ? "getMessages" : "",
      ].filter(Boolean),
      repetitionInterval: data
        ? data?.reminderPreferences?.length > 1
          ? "Daily"
          : "Weekly"
        : "",
      dayOfWeek: data
        ? data?.reminderPreferences?.length > 1
          ? ""
          : Object?.keys(data?.reminderPreferences[0])[0]
        : "",
      time: data ? Object?.values(data?.reminderPreferences[0])[0] : null,
      timeZone: data?.timeZone ? data.timeZone : dayjs.tz.guess(),
      optOut: data?.optOut ? data.optOut : false,
    }));

    if (data) {
      data.reminderPreferences.length === 1
        ? setShowDayOfWeekInput(true)
        : setShowDayOfWeekInput(false);
      reset({
        repetitionInterval:
          data.reminderPreferences.length > 1 ? "Daily" : "Weekly",
        dayOfWeek:
          data.reminderPreferences.length > 1
            ? ""
            : Object.keys(data.reminderPreferences[0])[0],
        time: dayjs(
          `2022-01-01 ${Object.values(data.reminderPreferences[0])[0]}`
        ),
        timeZone: data.timeZone,
      });

      setReminderSetting(
        [
          data?.getEmails ? "getEmails" : "",
          data?.getMessages && phone && Affordability !== "No"
            ? "getMessages"
            : "",
        ].filter(Boolean)
      );
      return setFetchRecord(true);
    } else {
      reset({
        repetitionInterval: "",
        dayOfWeek: "",
        time: null,
        timeZone: dayjs.tz.guess(),
      });
      setReminderSetting([]);
      return setFetchRecord(true);
    }
  }

  useEffect(() => {
    getExistingInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = useCallback(
    async (data) => {
      try {
        const formattedTime = data.time;
        let reminderRecordPayload = {
          timeZone: data.timeZone,
          getEmails: data.reminderSetting.includes("getEmails"),
          getMessages: data.reminderSetting.includes("getMessages"),
          getMissedCheckInEmails: data.reminderSetting.includes("getEmails"),
          getMissedCheckInMessages:
            data.reminderSetting.includes("getMessages"),
        };

        // set reminder preferences
        if (data.repetitionInterval === "Weekly") {
          reminderRecordPayload["reminderPreferences"] = [
            { [data.dayOfWeek]: formattedTime },
          ];
        } else {
          reminderRecordPayload["reminderPreferences"] = [
            { Sunday: formattedTime },
            { Monday: formattedTime },
            { Tuesday: formattedTime },
            { Wednesday: formattedTime },
            { Thursday: formattedTime },
            { Friday: formattedTime },
            { Saturday: formattedTime },
          ];
        }
        await updateData("/reminder", reminderRecordPayload, userId);
        setToastVisibility(true);

        if (data.reminderSetting.includes("getEmails")) {
          const dayOfWeek = dayjs().format("dddd");
          const userSelectedTime =
            reminderRecordPayload.reminderPreferences.find(
              (pref) => pref[dayOfWeek]
            );

          if (userSelectedTime) {
            const timeInUtc = getTimeInUtc(
              userSelectedTime[dayOfWeek],
              reminderRecordPayload.timeZone
            );

            if (!timeInUtc?.isPast) {
              await apiCall("post", "/scheduleCheckInReminder", {
                body: {
                  email,
                  userId,
                  phone,
                  time: timeInUtc?.utcTime,
                  timeZone: reminderRecordPayload.timeZone,
                },
              });
            }
          }
        }

        const identifyObj = new amplitude.Identify();
        if (reminderRecordPayload) {
          let Notifications = "OFF";
          if (
            reminderRecordPayload?.getEmails &&
            !reminderRecordPayload?.getMessages
          ) {
            Notifications = "Email";
          }

          if (
            reminderRecordPayload?.getMessages &&
            !reminderRecordPayload?.getEmails
          ) {
            Notifications = "SMS";
          }

          if (
            reminderRecordPayload?.getMessages &&
            reminderRecordPayload?.getEmails
          ) {
            Notifications = "Email,SMS";
          }
          identifyObj.set("Notifications", Notifications);
        }
        amplitude.identify(identifyObj);
        amplitude.logEvent("Notifications_Updated");
        return setDefaultValue(watchedValues);
      } catch (error) {
        console.log(error.message);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userId, watchedValues, updateData, email]
  );

  return (
    <>
      {fetchRecord ? (
        <Stack component={"form"} onSubmit={handleSubmit(onSubmit)}>
          <NewRadioButton
            control={control}
            name="repetitionInterval"
            label="How Often?"
            options={repetitionInterval}
            componentStyle={{ width: "100%" }}
            onChangeOption={(selectedValue) => {
              if (selectedValue === "Daily") {
                showDayOfWeekInput && setShowDayOfWeekInput(false);
              } else !showDayOfWeekInput && setShowDayOfWeekInput(true);
            }}
            description="For most people, weekly is best"
          />

          <Collapse sx={{ width: "100%" }} in={Boolean(showDayOfWeekInput)}>
            <NewRadioButton
              control={control}
              name="dayOfWeek"
              label="Which day?"
              options={dayOfWeek}
              description="Pick a day without socials day before"
              componentStyle={{ mt: "28px" }}
            />
          </Collapse>

          <Box width={"100%"} mt={"28px"}>
            <ElementLabel
              label={"What time?"}
              description={"Pick a time that’s before you eat"}
            />
            <Box
              sx={{
                mt: "6px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-end",
                gap: { xs: "5px", sm: "18px" },
                width: "100%",
              }}
            >
              <NewTimePicker
                required={true}
                defaultValue={{
                  hours: dayjs(formState?.defaultValues?.time)
                    .add(0, "hour")
                    .format("hh"),
                  minutes: dayjs(formState.defaultValues.time)
                    .add(0, "h")
                    .format("mm"),
                  isAMSelected: dayjs(formState.defaultValues.time)
                    .add(0, "h")
                    .format("a"),
                }}
                onChange={(newValue) => {
                  setValue("time", newValue); // Update the form value
                }}
                stackStyle={{ mt: 0 }}
              />
              <SelectInput
                control={control}
                name="timeZone"
                label=""
                options={timeZones}
                removeMargin
                componentStyle={{ maxWidth: { xs: "120px", sm: "280px" } }}
              />
            </Box>
            <ErrorText error={formState?.errors?.time} />
          </Box>

          <CheckBox
            control={control}
            setValue={setValue}
            name="reminderSetting"
            options={[
              {
                label: "Email",
                value: "getEmails",
                onClick: () => {},
              },
              {
                label: "SMS",
                value: "getMessages",
                disabled: defaultValue.optOut
                  ? true
                  : phone && Affordability !== "No"
                  ? false
                  : true,
                onClick: () => {
                  !phone && setOpenDialog(true);
                },
                infoText:
                  Affordability === "No" ? (
                    "Sorry we can't send SMS reminders to your country"
                  ) : defaultValue.optOut ? (
                    <Typography
                      component={"span"}
                      sx={{ fontSize: "14px", color: theme.text.basic }}
                    >
                      SMS 'START' to{" "}
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: theme.palette.primary.main,
                        }}
                        component={"a"}
                        href="sms:+18142613194&body=START"
                      >
                        +1 814 261 3194
                      </Typography>{" "}
                      to re-enable SMS reminders
                    </Typography>
                  ) : undefined,
              },
            ]}
            defaultValue={reminderSetting}
          />

          <PrimaryButton
            disabled={disableSubmit}
            isLoading={loading}
            type="submit"
            sx={{
              mt: "48px",
              width: { xs: "100%", md: "unset" },
              alignSelf: "center",
            }}
          >
            Update
          </PrimaryButton>
          <BtnToast
            text={"Updated"}
            visibility={toastVisibility}
            setVisibility={setToastVisibility}
          />
        </Stack>
      ) : (
        <Stack>
          <Skeleton
            sx={{ height: { xs: "85px", sm: "107px" }, width: "100%" }}
          />
          <Skeleton
            sx={{
              mt: "24px",
              height: { xs: "85px", sm: "107px" },
              width: "100%",
            }}
          />
          <Skeleton
            sx={{
              mt: "24px",
              height: { xs: "90px", sm: "116px" },
              width: "100%",
            }}
          />
          <Skeleton sx={{ mt: "38px", height: "84px", width: "100%" }} />
          <Skeleton
            sx={{
              mt: "48px",
              height: { xs: "50px", sm: "54px" },
              width: { xs: "200px", sm: "220px" },
              alignSelf: "center",
            }}
          />
        </Stack>
      )}

      <SetPhoneNumberDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
      />
    </>
  );
};

export default Reminders;
