import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useAuth = create(
  persist(
    (set) => ({
      isLoggedIn: false,
      userId: undefined,
      email: undefined,
      name: undefined,
      emailVerified: false,
      phone: undefined,
      phoneVerified: undefined,
      sex: undefined,
      dateOfBirth: undefined,
      unitPreference: "Imperial", // 1."Imperial {in/pound}", 2. "Metric {cm/kg}"
      height: undefined,
      // reminder settings
      repetitionInterval: "",
      dayOfWeek: "",
      time: null,
      timeZone: "",
      getEmails: false,
      getMessages: "",
      getMissedCheckInEmails: "",
      getMissedCheckInMessages: "",
      reminderPreferences: [],
      referrer: undefined,
      Affordability: undefined,
      CloseLeadStatus: undefined,

      setUseDetails: (rest) => {
        set((state) => ({
          ...state,
          ...rest,
        }));
      },

      logout: () => {
        set(() => ({
          isLoggedIn: false,
          userId: undefined,
          name: undefined,
          email: undefined,
          emailVerified: false,
          phone: undefined,
          phoneVerified: undefined,
          sex: undefined,
          dateOfBirth: undefined,
          unitPreference: "Imperial",
          repetitionInterval: "",
          dayOfWeek: "",
          time: null,
          timeZone: "",
          getEmails: false,
          getMessages: "",
          getMissedCheckInEmails: "",
          getMissedCheckInMessages: "",
          reminderPreferences: [],
          Affordability: undefined,
        }));
      },
    }),
    { name: "user" }
  )
);
