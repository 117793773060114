import * as yup from "yup";

export const TechSupportSchema = yup.object().shape({
  steps: yup
    .string()
    .min(6, "Steps must be at least 6 characters")
    .required("Steps is required"),
  expectedResult: yup
    .string()
    .min(6, "Expectation field must be at least 6 characters")
    .required("Expectation field is required"),
  reality: yup
    .string()
    .min(6, "Reality field must be at least 6 characters")
    .required("Reality field is required"),
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Email is required"),
  file: yup
    .array()
    .nullable()
    .max(3, "Sorry, you can only upload up to 3 files at a time")
    .test(
      "fileSize",
      "File size is too large",
      (value, { createError, path }) => {
        if (!value) return true;

        let valid = true;
        let errorMessage = "";

        // Loop through each file and check its size
        value.forEach((file) => {
          if (file.size > 1024 * 1024 * 20) {
            valid = false;
            errorMessage += `File ${file.name} size exceeds the limit of 20MB. `;
          }
        });

        return (
          valid ||
          createError({
            message: errorMessage,
            path: path,
          })
        );
      }
    ),
});
