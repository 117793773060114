import React from "react";
import MainContainer from "../components/global/container/MainContainer";
import { Stack } from "@mui/material";
import Reminders from "../components/settings/forms/Reminders";
import TabComponent from "../components/settings/TabComponent";
import Privacy from "../components/settings/forms/Privacy";
import { useLocation, useNavigate } from "react-router-dom";

const Settings = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(
    location.pathname === "/settings/notification" ? 0 : 1
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue) navigate("/settings/privacy");
    else navigate("/settings/notification");
  };
  return (
    <MainContainer>
      {/* container */}
      <TabComponent
        value={value}
        handleChange={handleChange}
        tabItem={["Notifications", "Privacy"]}
      />
      <Stack
        sx={{
          mt: "48px",
          display: "grid",
          gridTemplateColumns: { xs: "1fr", lg: "auto 1fr" },
          gap: "41px",
          width: "100%",
        }}
      >
        {value === 0 && <Reminders />}
        {value === 1 && <Privacy />}
      </Stack>
    </MainContainer>
  );
};

export default Settings;
