import * as amplitude from "@amplitude/analytics-browser";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import MobileNumberInput from "../../global/form/MobileNumberInput";
import PrimaryButton from "../../global/button/PrimaryButton";
import { useAuth } from "../../../hooks/store/useAuth";
import PhoneEmailDialog from "../dialog/PhoneEmailDialog";
import useFindData from "../../../hooks/useFindData";
import { toast } from "react-toastify";
import MainContainer from "../../global/container/MainContainer";
import NewInputField from "../../global/form/NewInputField";
import { measurementUnits } from "../../../constants/measurementUnit";
import { cmToInches, inchesToCm } from "../../../functions/unitConvertor";
import MetricsNumberInput from "../../global/form/MetricsNumberInput";
import useUpdateData from "../../../hooks/useUpdateData";
import { useNavigate } from "react-router-dom";
import { UpdateProfileSchema } from "../../../validators/UpdateProfileSchema";

const UpdateEmailPhone = () => {
  useEffect(() => {
    amplitude.logEvent("Profile_Visited");
  }, []);

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogInfo, setDialogInfo] = useState({
    title: "Update your Contact Information",
    description: "",
  });

  const { phone, email, height, unitPreference, userId, setUseDetails } =
    useAuth();
  const [formData, setFormData] = useState({});
  const { findData, loading: loadingFind } = useFindData();
  const { updateData } = useUpdateData();
  const { text } = useTheme();
  const navigate = useNavigate();

  const { handleSubmit, control, formState, watch } = useForm({
    defaultValues: {
      number: phone,
      email: email,
      unitPreference,
      height:
        unitPreference === measurementUnits.IMPERIAL
          ? height
          : inchesToCm(height),
    },
    resolver: yupResolver(UpdateProfileSchema),
    mode: "onChange",
  });
  const {
    email: inputEmail,
    number: inputNumber,
    height: inputHeight,
  } = watch();
  const currentHeight =
    unitPreference === measurementUnits.IMPERIAL ? height : inchesToCm(height);
  const isFieldUpdated =
    email !== inputEmail ||
    (inputNumber !== "" && phone !== inputNumber) ||
    currentHeight !== inputHeight;

  // function for call on submit form
  const onSubmit = async (data) => {
    const height =
      unitPreference === measurementUnits.IMPERIAL
        ? data?.height
        : inchesToCm(data.height);
    if (currentHeight !== height) {
      const allCheckIns = await findData("checkIns", "userId", "==", userId);
      const calculatedHeight =
        data?.unitPreference === measurementUnits.IMPERIAL
          ? data?.height
          : cmToInches(data?.height);

      // Update all check-ins concurrently using Promise.all
      await Promise.all(
        allCheckIns.map((checkIn) =>
          updateData("checkIns", { height: calculatedHeight }, checkIn.id)
        )
      );
      setUseDetails({ height: calculatedHeight });
      toast("Height updated in all check-ins successfully!");
      if (data.email === email && data.number === phone) {
        return navigate("/myprogress");
      }
    }

    setFormData(data);
    let description = "Are you sure you want to update your ";
    if (data.email !== email) {
      let checkEmail = await findData("/users", "email", "==", data.email);
      if (checkEmail)
        return toast(
          "Sorry, this email is already associated with an existing account. Please use a different email.",
          {
            type: "error",
          }
        );
      description += `email to ${data.email}`;
    }
    if (data.number !== phone && data.number !== "") {
      let checkPhone = await findData("/users", "phone", "==", data.number);
      if (checkPhone)
        return toast("This phone number is already in use.", {
          type: "error",
        });
      description += `${data.email !== email ? " and " : ""}`;
      description += `phone to ${data.number}`;
    }

    // prevent unnecessary form submission
    if (data.email === email && data.number === phone) {
      return toast("Everything is same as before.", { type: "warning" });
    }

    setDialogInfo({
      title: dialogInfo.title,
      description: description,
    });
    return setOpenDialog(true);
  };

  return (
    <MainContainer>
      <Stack component={"form"} onSubmit={handleSubmit(onSubmit)}>
        <Typography
          sx={{
            mt: "6px",
            color: text.primary,
            fontSize: { xs: "12px", sm: "16px" },
            fontWeight: 600,
            textTransform: "uppercase",
          }}
        >
          My Profile
        </Typography>

        <NewInputField
          name={"email"}
          type={"email"}
          label={"Email"}
          placeholder={"Enter your Email"}
          control={control}
          errorMsg={true}
          stackXs={{ mt: "24px", maxWidth: "400px" }}
        />
        <MobileNumberInput
          label={"Phone"}
          stackXs={{ mt: "24px" }}
          control={control}
          name="number"
          sx={{ mt: "0px", maxWidth: "414px" }}
        />

        <MetricsNumberInput
          type="number"
          name="height"
          label="Height"
          control={control}
          stackXs={{ mt: "24px", maxWidth: "400px" }}
        />

        <Stack
          sx={{
            flexDirection: { xs: "column-reverse", md: "row" },
            alignItems: "flex-end",
            justifyContent: { md: "flex-end" },
            gap: "24px",
            mt: "48px",
          }}
        >
          <PrimaryButton
            disabled={
              !isFieldUpdated ||
              !formState.isValid ||
              Object.keys(formState.errors).length > 0
            }
            isLoading={loadingFind || formState.isSubmitting}
            type="submit"
            sx={{
              mt: "48px",
              width: { xs: "100%", md: "unset" },
              alignSelf: "center",
            }}
          >
            Update
          </PrimaryButton>
        </Stack>

        <PhoneEmailDialog
          title={dialogInfo.title}
          description={dialogInfo.description}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          formData={formData}
        />
      </Stack>
    </MainContainer>
  );
};

export default UpdateEmailPhone;
