import React, { useEffect, useState } from "react";
import MainContainer from "../components/global/container/MainContainer";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import PrimaryButton from "../components/global/button/PrimaryButton";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useApiCall from "../hooks/useApiCall";
import NewInputField from "../components/global/form/NewInputField";
import palmImage from "../assets/other-images/palm.png";
import BtnToast from "../components/global/toast/BtnToast";
import useFindData from "../hooks/useFindData";
import * as amplitude from "@amplitude/analytics-browser";

const Login = () => {
  useEffect(() => {
    amplitude.logEvent("Login");
  }, []);

  const { text } = useTheme();
  const theme = useTheme();
  const { apiCall } = useApiCall();
  const { findData, loading } = useFindData();
  const [toastVisible, setToastVisible] = useState({
    visibility: false,
    type: "success",
    message: "",
  });
  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Please enter a valid email address")
      .required("Email is required"),
  });

  const { handleSubmit, control, formState, reset } = useForm({
    defaultValues: { email: "" },
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  //  use function for submit form and resend otp as well
  async function onSubmit(data) {
    let email = String(data.email).toLowerCase();
    try {
      // 1. check user exist or not
      let isAccountExist = await findData("/users", "email", "==", email);
      if (!isAccountExist) {
        return setToastVisible({
          visibility: true,
          type: "error",
          message: `${email} account doesn't exist`,
        });
      }

      // 2. send login link
      apiCall("post", "/loginLink", {
        body: { email: email },
      });

      reset();
      return setToastVisible({
        visibility: true,
        type: "success",
        message: `Click link sent to ${email} <br/>(check spam/folders too)`,
      });
    } catch (error) {
      if (error.request.status === 404) {
        return setToastVisible({
          visibility: true,
          type: "error",
          message: `No account found from ${email}`,
        });
      }
      return setToastVisible({
        visibility: true,
        type: "error",
        message: error.response.data.message,
      });
    }
  }
  return (
    <MainContainer>
      <Stack
        component={"form"}
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          mt: "32px",
          width: { xs: "100%", md: "424px" },
          mx: "auto",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Stack sx={{ alignItems: "center" }}>
          <Box
            sx={{
              padding: "8px",
              backgroundColor: "primary.main",
              borderRadius: "50%",
            }}
          >
            <Box
              component="img"
              src={palmImage}
              sx={{
                width: { xs: "32px", sm: "42px" },
              }}
            />
          </Box>
          <Typography
            sx={{
              color: text.primary,
              fontFamily: theme.typography.fontFamily2,
              fontSize: { xs: "26.5px", sm: "28px" },
              fontWeight: 600,
            }}
          >
            Welcome back!
          </Typography>
        </Stack>
        <NewInputField
          name={"email"}
          type={"email"}
          label={"Your email"}
          control={control}
          errorMsg={false}
          stackXs={{ mt: "28px" }}
        />
        <PrimaryButton
          disabled={!formState.isValid}
          type="submit"
          sx={{
            mt: { xs: "32px", sm: "44px" },
            width: { xs: "220px", md: "unset" },
          }}
          isLoading={loading}
        >
          Next
        </PrimaryButton>
        <BtnToast
          text={toastVisible.message}
          visibility={toastVisible.visibility}
          type={toastVisible.type}
          sx={{ mt: "6px" }}
          setVisibility={() => {
            setToastVisible((prev) => ({ ...prev, visibility: false }));
          }}
        />
      </Stack>
    </MainContainer>
  );
};

export default Login;
