import * as yup from "yup";
export const UpdateProfileSchema = yup.object().shape(
  {
    email: yup
      .string()
      .email("Please enter a valid email address")
      .required("Email is required"),
    number: yup
      .string()
      .nullable()
      .when("number", {
        is: (value) => value && value.length > 0,
        then: yup
          .string()
          .phone("Invalid phone number")
          .typeError("Phone number must be a valid number"),
        otherwise: yup.string(),
      }),
    height: yup
      .number()
      .typeError("please enter a valid height value")
      .required("Height value is required")
      .positive("Height value must be a positive number.")
      .when("unitPreference", (unitPreference, schema) =>
        unitPreference === "Metric"
          ? schema.min(121, "Please enter height in centimeters (cm).")
          : schema.min(48, "Enter height in inches")
      ),
  },
  [["number", "number"]]
);
