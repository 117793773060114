import { Skeleton as MuiSkeleton, alpha, useTheme } from "@mui/material";
import React from "react";

const Skeleton = ({ sx = {} }) => {
  const theme = useTheme();
  return (
    <MuiSkeleton
      animation="wave"
      sx={{
        bgcolor: alpha(theme.palette.primary.main, 0.1),
        transform: "scale(1)",
        width: "20px",
        height: "20px",
        borderRadius: "14px",
        ...sx,
      }}
    />
  );
};

export default Skeleton;
