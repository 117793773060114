import React, { useState } from "react";
import MainContainer from "../components/global/container/MainContainer";
import MainContent from "../components/on-boarding/MainContent";
import { Collapse, Stack, Typography, useTheme } from "@mui/material";
import DiyContent from "../components/on-boarding/DiyContent";
import GaugeFitContent from "../components/on-boarding/GaugeFitContent";
import { onBoardingDialogs } from "../constants/onBoardingContentDialogs";

const OnBoarding = () => {
  const theme = useTheme();
  const [openDialog, setOpenDialog] = useState(onBoardingDialogs.mainContent);
  return (
    <MainContainer>
      <Stack
        sx={{
          mt: theme.margin.margin1,
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: theme.text.disabled,
            fontSize: theme.typography.heading2,
            fontWeight: theme.typography.heading1Bold,
            cursor: "no-drop",
            textWrap: "nowrap",
          }}
        >
          CHECK-IN
        </Typography>
        <Typography
          sx={{
            color: theme.text.disabled,
            marginInline: "10px",
            fontSize: theme.typography.heading2,
            fontWeight: theme.typography.heading1Bold,
          }}
        >
          {">"}
        </Typography>
        <Typography
          sx={{
            color: theme.text.primary,
            fontSize: theme.typography.heading2,
            fontWeight: theme.typography.heading1Bold,
            cursor: "no-drop",
          }}
          onClick={() => {}}
        >
          CONFIRM
        </Typography>
      </Stack>
      <Collapse in={openDialog === onBoardingDialogs.mainContent}>
        <MainContent setOpenDialog={setOpenDialog} />
      </Collapse>
      <Collapse in={openDialog === onBoardingDialogs.diyContent}>
        <DiyContent />
      </Collapse>
      <Collapse in={openDialog === onBoardingDialogs.gaugeFitContent}>
        <GaugeFitContent />
      </Collapse>
    </MainContainer>
  );
};

export default OnBoarding;
