import * as amplitude from "@amplitude/analytics-browser";
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { useAuth } from "../../../hooks/store/useAuth";
import PrimaryButton from "../../global/button/PrimaryButton";
import useDeleteData from "../../../hooks/useDeleteData";
import useDeleteAllData from "../../../hooks/useDeleteAllData";
import { toast } from "react-toastify";
import ElementLabel from "../../global/form/ElementLabel";
import { useTheme } from "@mui/material";

export default function DeleteAccountDialog({ openDialog, setOpenDialog }) {
  const theme = useTheme();
  const { userId, logout } = useAuth();
  const { deleteDataById, loading } = useDeleteData();
  const { deleteDataByCondition, loading: deleteAllLoading } =
    useDeleteAllData();

  async function deleteAccount() {
    try {
      await deleteDataByCondition("/checkIns", "userId", "==", userId);
      await deleteDataById("/reminder", userId);
      await deleteDataById("/users", userId);
      toast("Your account was deleted", { type: "success" });

      amplitude.logEvent("Privacy_AccountDeleted");
      return logout();
    } catch (error) {
      console.error(error);
    }
  }

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <React.Fragment>
      <Dialog
        PaperProps={{
          style: {
            boxShadow: "none",
          },
        }}
        sx={{
          boxShadow: "none",
          "& .MuiBackdrop-root": {
            background: "rgba(255,255,255,0.7)",
          },
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: { md: "460px" },
              paddingInline: "18px",
              paddingBlock: "28px",
              backgroundColor: "#faf9f9",
              borderRadius: "20px",
            },
          },
        }}
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <ElementLabel
          label={"Are you sure?"}
          description={"All your data will be permanently deleted."}
          labelSx={{
            fontFamily: theme.typography.fontFamily2,
            fontSize: { xs: "18px", sm: "22px" },
            lineHeight: { xs: "22px", sm: "24px" },
            textAlign: "left",
          }}
          descriptionSx={{
            mt: { xs: "8px", sm: "12px" },
            fontSize: { xs: "14px", sm: "16px" },
            lineHeight: { xs: "16px", sm: "20px" },
            textAlign: "left",
          }}
        />
        <PrimaryButton
          onClick={deleteAccount}
          isLoading={loading || deleteAllLoading}
          sx={{
            mt: "24px",
            maxWidth: { md: "312px !important" },
            width: "100%",
          }}
        >
          Delete My Account
        </PrimaryButton>
      </Dialog>
    </React.Fragment>
  );
}
