import {
  Collapse,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import ErrorText from "./ErrorText";
import ErrorToolTip from "./ErrorToolTip";
export const InputFiled = ({
  label = "",
  type,
  name,
  control,
  sx,
  componentStyle = {},
  errorToolTip = true,
  errorMsg = true,
  errorSx = {},
  inputProps = {},
}) => {
  const theme = useTheme();
  return (
    <Stack sx={{ width: "100%", ...componentStyle }}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Stack>
            <Stack
              sx={{ flexDirection: "row", alignItems: "center", gap: "12px" }}
            >
              <Typography
                sx={{
                  color: theme.text.basic,
                  fontSize: theme.typography.heading1,
                  fontWeight: theme.typography.heading1Bold,
                  textWrap: "nowrap",
                  whiteSpace: "pre",
                  lineHeight: "16px",
                }}
              >
                {label}
              </Typography>
              <TextField
                // placeholder={placeholder}
                type={type}
                onChange={onChange}
                value={value}
                inputProps={inputProps}
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-input": {
                    color: theme.text.basic,
                    fontSize: theme.typography.heading1,
                    fontWeight: theme.typography.heading2Bold,
                    p: 0,
                    height: "unset",
                    minHeight: "unset",
                    borderBottom: "2px solid",
                    borderColor: alpha(theme.text.basic, 0.4),
                  },

                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },

                  "& .MuiOutlinedInput-input::placeholder": {
                    fontWeight: 400,
                  },

                  "& fieldset": { border: "none" },
                  "& .MuiOutlinedInput-root": {
                    paddingRight: "unset !important",
                  },
                  ...sx,
                }}
                InputProps={{
                  onWheel: (e) => e.target.blur(),
                  endAdornment: (
                    <InputAdornment position="end" sx={{ zIndex: 2 }}>
                      {error && errorToolTip && (
                        <ErrorToolTip errorText={error.message} />
                      )}
                    </InputAdornment>
                  ),
                  autoComplete: "off",
                }}
              />
            </Stack>
            <Collapse in={Boolean(error) && Boolean(errorMsg)}>
              <ErrorText error={error} errorSx={errorSx} />
            </Collapse>
          </Stack>
        )}
      />
    </Stack>
  );
};
