import React from "react";
import PrimaryButton from "../../global/button/PrimaryButton";
import ElementLabel from "../../global/form/ElementLabel";
import { useTheme } from "@mui/material";

const UpdateAlert = ({ title, description, openNextContainer }) => {
  const theme = useTheme();
  return (
    <>
      <ElementLabel
        label={title}
        description={description}
        labelSx={{
          fontFamily: theme.typography.fontFamily2,
          fontSize: { xs: "18px", sm: "22px" },
          lineHeight: { xs: "22px", sm: "24px" },
          textAlign: "left",
        }}
        descriptionSx={{
          mt: { xs: "8px", sm: "12px" },
          fontSize: { xs: "14px", sm: "16px" },
          lineHeight: { xs: "16px", sm: "20px" },
          textAlign: "left",
        }}
      />
      <PrimaryButton
        onClick={openNextContainer}
        sx={{
          mt: "24px",
          maxWidth: { md: "312px !important" },
          width: "100%",
        }}
      >
        Update Details
      </PrimaryButton>
    </>
  );
};

export default UpdateAlert;
