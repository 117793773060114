import * as amplitude from "@amplitude/analytics-browser";
import { Box, Typography, alpha, useTheme } from "@mui/material";
import React from "react";
import PrimaryButton from "../global/button/PrimaryButton";
import { useAuth } from "../../hooks/store/useAuth";
import { useCommon } from "../../hooks/store/useCommon";
import { matchCountryCode } from "../../utils/SmsSeviceChecker";
const AdviseContent = ({
  title = "",
  description = [],
  hideAdditionalInfo = false,
}) => {
  const theme = useTheme();
  const { phone, CloseLeadStatus } = useAuth();
  const { settings } = useCommon();
  const isCountryAffordable = phone
    ? matchCountryCode(phone, settings?.SmsServicesAvailability)
    : true;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "12px 16px",
        background: alpha(theme.palette.background.primary, 0.1),
        borderRadius: "16px",
      }}
    >
      <Typography
        sx={{
          fontFamily: theme.typography.fontFamily2,
          fontSize: { xs: "19.8px", sm: "22px" },
          color: theme.text.primary,
          fontWeight: theme.typography.headingSecondaryBold,
        }}
      >
        {title}
      </Typography>

      <Box>
        {description.map((paragraph, index) => (
          <Typography
            key={index}
            sx={{
              color: theme.text.basic,
              fontSize: { xs: "14px", sm: "16px" },
              marginTop: "4px",
              lineHeight: "24px",
            }}
          >
            {paragraph}
          </Typography>
        ))}
      </Box>

      {!hideAdditionalInfo &&
        isCountryAffordable &&
        CloseLeadStatus !== "BBB Customer" && 
        (
          <>
            <Typography
              sx={{
                mt: "18px",
                fontFamily: theme.typography.fontFamily2,
                fontSize: theme.typography.headingBasic,
                color: theme.text.basic,
                fontWeight: theme.typography.headingBasicBold,
              }}
            >
              Got questions?
            </Typography>

            <Typography
              sx={{
                color: theme.text.basic,
                fontSize: { xs: "14px", sm: "16px" },
                marginTop: theme.margin.margin3,
                lineHeight: "18px",
              }}
            >
              Let’s pinpoint your 1 Small Change for Maximum Results.
            </Typography>
            <Typography
              sx={{
                color: theme.text.basic,
                fontSize: { xs: "14px", sm: "16px" },
                marginTop: theme.margin.margin2,
                lineHeight: "18px",
              }}
            >
              We’ll do this by understanding your:
            </Typography>

            <Box
              component={"ul"}
              sx={{
                listStylePosition: "outside",
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                justifyContent: "center",
                marginLeft: "20px",
              }}
            >
              <Typography
                component={"li"}
                sx={{
                  color: theme.text.basic,
                  fontSize: { xs: "14px", sm: "16px" },
                  marginTop: theme.margin.margin4,
                  lineHeight: "16px",
                }}
              >
                <Typography
                  component={"span"}
                  sx={{
                    fontWeight: theme.typography.headingMainBold,
                    fontSize: { xs: "14px", sm: "16px" },
                  }}
                >
                  Body:
                </Typography>{" "}
                what is normal for you
              </Typography>
              <Typography
                component={"li"}
                sx={{
                  color: theme.text.basic,
                  fontSize: { xs: "14px", sm: "16px" },
                  marginTop: theme.margin.margin4,
                  lineHeight: "16px",
                }}
              >
                <Typography
                  component={"span"}
                  sx={{
                    fontWeight: theme.typography.headingMainBold,
                    fontSize: { xs: "14px", sm: "16px" },
                  }}
                >
                  Emotions:
                </Typography>{" "}
                your specific triggers and relationship with food
              </Typography>
              <Typography
                component={"li"}
                sx={{
                  color: theme.text.basic,
                  fontSize: { xs: "14px", sm: "16px" },
                  marginTop: theme.margin.margin4,
                  lineHeight: "16px",
                }}
              >
                <Typography
                  component={"span"}
                  sx={{
                    fontWeight: theme.typography.headingMainBold,
                    fontSize: { xs: "14px", sm: "16px" },
                  }}
                >
                  Beliefs:
                </Typography>{" "}
                what you believe about yourself and what’s real
              </Typography>
            </Box>
            <PrimaryButton
              component={"a"}
              onClick={() => {
                amplitude.logEvent("Progress_SetterCallClick");
              }}
              href={
                "https://www.coachviva.com/resources/unstuck-call?utm_source=bfcalc"
              }
              target={"_blank"}
              sx={{ mt: "20px", mx: "auto", p: "24px", maxWidth: "220px" }}
            >
              Book Free 15-min Call
            </PrimaryButton>
          </>
        )}
    </Box>
  );
};

export default AdviseContent;