import * as amplitude from "@amplitude/analytics-browser";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PrimaryButton from "../global/button/PrimaryButton";
import { Box, Stack, Tab, Tabs, useTheme } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import OutlinedButton from "../global/button/OutlinedButton";
import { InputFiledWithSelect } from "../global/form/InputFiledWithSelect";
import {
  MeasurementsSourceMen,
  MeasurementsSourceWomen,
} from "../../inputConfigs/MeasurementsSource";
import {
  MenMeasurementSchema,
  WomenMeasurementSchema,
} from "../../validators/MeasurementSchema";
import { useFirstCheckIn } from "../../hooks/store/useFirstCheckIn";
import { Link, useNavigate } from "react-router-dom";
import MainContainer from "../global/container/MainContainer";
import Breadcrumb from "./Breadcrumb";
import BtnToast from "../global/toast/BtnToast";
import { useCommon } from "../../hooks/store/useCommon";

const BeforeLoggedInMeasurementsForm = () => {
  const navigate = useNavigate();
  const [showToast, setShowToast] = useState(false);
  const {
    goal,
    height,
    weight,
    waist,
    neck,
    hip,
    gender,
    setMeasurement,
    unitPreference,
    setUnitPreference,
  } = useFirstCheckIn();
  const { fromTrigger } = useCommon();

  useEffect(() => {
    if (goal !== "")
      amplitude.logEvent("Measurement_Visited", {
        Trigger: fromTrigger ? fromTrigger : undefined,
      });
    else navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goal, fromTrigger]);

  const theme = useTheme();

  // code for tab
  const [value, setValue] = React.useState(unitPreference === "Metric" ? 1 : 0);
  const handleChange = (event, newValue) => {
    setUnitPreference({ unitPreference: newValue ? "Metric" : "Imperial" });
    setValue(newValue);
  };

  const {
    handleSubmit,
    control,
    formState,
    setValue: setFormValue,
  } = useForm({
    defaultValues: {
      height,
      weight,
      waist,
      neck,
      ...(gender === "Female" ? { hip } : {}),
      unitPreference,
    },
    resolver: yupResolver(
      gender === "Female" ? WomenMeasurementSchema : MenMeasurementSchema
    ),
    mode: "onTouched",
  });

  useEffect(() => {
    setFormValue("unitPreference", unitPreference);
  }, [unitPreference, setFormValue]);

  // display error message onsubmit form if any form error exist
  useEffect(() => {
    if (Object.keys(formState.errors).length > 0) setShowToast(true);
  }, [formState.errors]);

  const onSubmit = useCallback(
    (data) => {
      setMeasurement(data);
      amplitude.logEvent("Measurement_Submitted", {
        Trigger: fromTrigger ? fromTrigger : undefined,
      });
      return navigate("/result");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fromTrigger]
  );

  return (
    <MainContainer>
      <Breadcrumb />
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{
          position: "sticky",
          top: { xs: "100px", sm: "124px" },
          zIndex: 99,
        }}
      >
        <Box
          sx={{
            width: "100%",
            bgcolor: "background.main",
          }}
        >
          <Tabs
            sx={{
              width: "calc(100% + 48px)",
              ml: "-24px",
              marginTop: theme.margin.margin2,
              boxShadow: "0px 5px 4px #E9ECEF",
              "&.MuiTabs-root": {
                mt: 0,
              },
            }}
            value={value}
            onChange={handleChange}
            centered
          >
            <Tab
              label="Imperial"
              sx={{
                width: "50%",
                fontFamily: theme.typography.fontFamily2,
                fontSize: "18px",
                fontWeight: theme.typography.heading1Bold,
                textTransform: "capitalize",
              }}
            />
            <Tab
              label="Metric"
              sx={{
                width: "50%",
                fontFamily: theme.typography.fontFamily2,
                fontSize: "18px",
                fontWeight: theme.typography.heading1Bold,
                textTransform: "capitalize",
              }}
            />
          </Tabs>
        </Box>
      </Stack>

      <OutlinedButton
        component={Link}
        to="/tape-reminder"
        sx={{
          width: "100%",
          fontSize: "16px !important",
          lineHeight: "16px !important",
          borderRadius: "16px !important",
          marginTop: theme.margin.margin1,
        }}
      >
        I don’t have a tape handy
      </OutlinedButton>
      <Stack
        component={"form"}
        onSubmit={handleSubmit(onSubmit)}
        sx={{ alignItems: "flex-end", mb: "24px" }}
      >
        {(gender === "Female"
          ? MeasurementsSourceWomen
          : MeasurementsSourceMen
        ).map((inputConfig) => (
          <InputFiledWithSelect
            key={inputConfig.name}
            name={inputConfig.name}
            label={inputConfig.label}
            icon={inputConfig.icon}
            control={control}
            description={inputConfig?.description}
            iconSx={inputConfig?.iconSx ? inputConfig?.iconSx : {}}
          />
        ))}

        <Stack
          sx={{
            mt: "24px",
            width: "100%",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PrimaryButton type="submit">Get Results</PrimaryButton>
          <BtnToast
            text={"Please enter valid values."}
            visibility={showToast}
            setVisibility={setShowToast}
            type="error"
          />
        </Stack>
      </Stack>
    </MainContainer>
  );
};

export { BeforeLoggedInMeasurementsForm };
