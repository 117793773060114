import * as amplitude from "@amplitude/analytics-browser";
import { yupResolver } from "@hookform/resolvers/yup";
import tapeSVG from "../../icons/tape.svg";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import useSetData from "../../hooks/useSetData";
import { useForm } from "react-hook-form";
import { timeZones } from "../../inputConfigs/reminderSource";
import { TapeReminderSchema } from "../../validators/TapeReminder";
import { generateUniqueId } from "../../functions/generateUniqueId";
import MainContainer from "../global/container/MainContainer";
import NewDatePicker from "../global/form/NewDatePicker";
import ElementLabel from "../global/form/ElementLabel";
import ErrorText from "../global/form/ErrorText";
import NewInputField from "../global/form/NewInputField";
import MobileNumberInput from "../global/form/MobileNumberInput";
import PrimaryButton from "../global/button/PrimaryButton";
import NewTimePicker from "../global/form/NewTimePicker";
import { SelectInput } from "../global/form/SelectInput";
import { useEffect, useState } from "react";
import BtnToast from "../global/toast/BtnToast";
import VerifyMobileDialog from "./VerifyMobileDialog";
import useFindData from "../../hooks/useFindData";
import { useCommon } from "../../hooks/store/useCommon";
const dayjs = require("dayjs");
const timezone = require("dayjs/plugin/timezone");
const utc = require("dayjs/plugin/utc");

dayjs.extend(utc);
dayjs.extend(timezone);

const TapeReminderForm = ({ setOpenFrom, setPhone }) => {
  const theme = useTheme();
  const { loading, setData } = useSetData();
  const { findData, loading: findDataLoading } = useFindData();
  const [showToast, setShowToast] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState({});
  const { fromTrigger } = useCommon();
  const { handleSubmit, control, formState, setValue } = useForm({
    defaultValues: {
      email: "",
      name: "",
      date: dayjs(),
      number: "",
      time: null,
      timeZone: dayjs.tz.guess(),
    },
    resolver: yupResolver(TapeReminderSchema),
    mode: "onChange",
  });

  useEffect(() => {
    amplitude.logEvent("TapeReminder_Visited", {
      Trigger: fromTrigger ? fromTrigger : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data) => {
    try {
      amplitude.logEvent("TapeReminder_Submitted");
      let id = generateUniqueId();
      // const time =
      data.date.setHours(...data.time.split(":"));
      setPhone(data?.number);

      // Combine date and time into a full datetime string
      const combinedDateTime = dayjs.tz(
        `${dayjs(data.date).format("YYYY-MM-DD")} ${data.time}`,
        data.timeZone
      );

      // Check if the combined datetime is in the future
      const isFutureTime = combinedDateTime.isAfter(dayjs());

      if (!isFutureTime) {
        return setShowToast(true);
      }

      // 1. submit form without phone number
      if (!data?.number) {
        // we don't need to verify mobile number because user does not provide number
        await setData(
          "/tapeReminder",
          {
            emailVerified: false,
            ...data,
            recordId: id,
          },
          id
        );
        return setOpenFrom();
      }

      // 2. tape request with phone number
      if (data?.number) {
        // check number is verified or not
        const user = await findData("/users", "phone", "==", data.number);
        // if number is exist and verified
        if (user && user[0]?.phoneVerified) {
          await setData(
            "/tapeReminder",
            {
              emailVerified: false,
              ...data,
              recordId: id,
            },
            id
          );
          return setOpenFrom();
        } else {
          // if number is exist and not verified
          setFormData({
            emailVerified: false,
            ...data,
            recordId: id,
            userId: user ? user[0]?.id : undefined,
          });
          return setOpenDialog(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <MainContainer>
      <Stack
        sx={{
          mt: theme.margin.margin1,
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: theme.text.primary,
            fontSize: theme.typography.heading2,
            fontWeight: theme.typography.heading1Bold,
            cursor: "pointer",
          }}
          onClick={() => {}}
        >
          REMINDER
        </Typography>
        <Typography
          sx={{
            color: theme.text.disabled,
            marginInline: "10px",
            fontSize: theme.typography.heading2,
            fontWeight: theme.typography.heading1Bold,
          }}
        >
          {">"}
        </Typography>
        <Typography
          sx={{
            color: theme.text.disabled,
            fontSize: theme.typography.heading2,
            fontWeight: theme.typography.heading1Bold,
            cursor: "no-drop",
          }}
          onClick={() => {}}
        >
          CONFIRM
        </Typography>
      </Stack>
      <Stack
        component={"form"}
        onSubmit={handleSubmit(onSubmit)}
        sx={{ my: "24px", alignItems: "center" }}
      >
        <Typography
          sx={{
            width: "100%",
            textAlign: "center",
            color: theme.text.basic,
            fontSize: theme.typography.heading1,
            fontWeight: theme.typography.heading1Bold,
            background: `url(${tapeSVG})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            padding: "10px",
          }}
        >
          Don’t own a tape?{" "}
          <Typography
            component={"span"}
            sx={{
              color: theme.text.basic,
              fontSize: theme.typography.heading1,
            }}
          >
            I use{" "}
            <Typography
              component={"a"}
              href="https://www.amazon.com/gp/product/B082W886W9/ref=ppx_yo_dt_b_asin_title_o01_s00?ie=UTF8&th=1"
              target="_blank"
              sx={{ color: "#758bf1", fontSize: theme.typography.heading1 }}
              onClick={() => {
                amplitude.logEvent("TapeReminder_ClickBuyLink");
              }}
            >
              this one
            </Typography>
          </Typography>
        </Typography>

        <NewDatePicker
          value={dayjs()}
          onChange={(newValue) => {
            setValue("date", newValue); // Update the form value
          }}
          error={formState?.errors?.date}
          label={"Date to remind you to measure"}
          stackSx={{ marginTop: "28px" }}
        />

        <Box width={"100%"} mt={"28px"}>
          <ElementLabel label={"Time"} />
          <Box
            sx={{
              mt: "6px",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-end",
              gap: { xs: "5px", sm: "18px" },
              width: "100%",
            }}
          >
            <NewTimePicker
              defaultValue={{
                hours: dayjs().add(2, "hour").startOf("hour").format("hh"),
                minutes: dayjs().add(2, "h").startOf("hour").format("mm"),
                isAMSelected: dayjs().add(2, "h").startOf("hour").format("a"),
              }}
              onChange={(newValue) => {
                setValue("time", newValue); // Update the form value
              }}
              stackStyle={{ mt: 0 }}
            />
            <SelectInput
              control={control}
              name="timeZone"
              label=""
              options={[
                {
                  value: dayjs.tz.guess(),
                  label: `${dayjs()
                    .tz(dayjs.tz.guess())
                    .format("Z")} ${dayjs.tz.guess()}`,
                },
                ...timeZones,
              ]}
              removeMargin
              componentStyle={{ maxWidth: { xs: "120px", sm: "280px" } }}
            />
          </Box>
          <ErrorText error={formState?.errors?.time} />
        </Box>

        <NewInputField
          name={"email"}
          type={"text"}
          label={"Email to remind you at"}
          control={control}
          errorToolTip={false}
          errorMsg={true}
          stackXs={{ mt: "28px" }}
          componentStyle={{ marginTop: theme.margin.margin1 }}
          errorSx={{ mt: "6px", textAlign: "center" }}
        />

        <MobileNumberInput
          control={control}
          name="number"
          label={"SMS reminder"}
          description={"As emails can be easily missed"}
          stackXs={{ mt: "28px" }}
        />

        <NewInputField
          name={"name"}
          type={"text"}
          label={"Your name"}
          control={control}
          errorToolTip={false}
          errorMsg={true}
          componentStyle={{ marginTop: theme.margin.margin1 }}
          stackXs={{ mt: "28px" }}
          errorSx={{ mt: "6px", textAlign: "center" }}
        />

        <PrimaryButton
          type="submit"
          isLoading={loading || findDataLoading}
          sx={{
            mt: "48px",
          }}
        >
          Remind Me
        </PrimaryButton>
        <BtnToast
          text={"Set time in future"}
          visibility={showToast}
          setVisibility={setShowToast}
          type="error"
        />
      </Stack>
      {openDialog && (
        <VerifyMobileDialog
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          formData={formData}
          callBack={setOpenFrom}
        />
      )}
    </MainContainer>
  );
};

export default TapeReminderForm;
